import {Kbd as RadixUIKbd} from '@radix-ui/themes'
import {KBarAnimator, KBarPositioner, KBarSearch} from 'kbar'
import styled, {css} from 'styled-components'

type IItemProps = {
  active: boolean
}

export const Item = styled.div<IItemProps>`
  ${({active, theme}) => css`
    padding: 1.2rem 1.6rem;
    background: ${theme.colors.commandBarColor};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: ${theme.font.family.IBM};
    margin: 0;
    cursor: pointer;
    color: ${active ? theme.colors.title : theme.colors.text};
  `}
`

export const Positioner = styled(KBarPositioner)`
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  inset: 0;
  padding: 14vh 1.6rem 1.6rem;
  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
`

export const Search = styled(KBarSearch)`
  ${({theme}) => css`
    padding: 1.6rem 1.6rem;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    margin: 0;
    background: ${theme.colors.commandBarColor};
    border-bottom: 0.1rem solid ${theme.colors.borderColor};
    color: ${theme.colors.text};
    font-family: ${theme.font.family.IBM};
  `}
`

export const GroupName = styled.div`
  ${({theme}) => css`
    padding: 0.8rem 1.6rem;
    font-size: 1rem;
    font-family: ${theme.font.family.IBM};
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    background: ${theme.colors.commandBarColor};
  `}
`

export const Kbd = styled(RadixUIKbd)`
  ${({theme}) => css`
    background: rgba(255, 255, 255, 0.1);
    color: ${theme.colors.text};
    width: 2.4rem;
    padding-top: 0.3rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: ${theme.font.family.FiraCode};
    line-height: 0;
    border-radius: 0.4rem;
    transition: background-color 0.2s ease-in-out;
    font-size: 1.2rem;
    cursor: pointer;
  `}
`

export const Shortcut = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 0.8rem;
`

export const Action = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`

export const ActionRow = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;

    span {
      font-size: 1.4rem;
      font-family: ${theme.font.family.IBM};
    }
  `}
`

export const Animator = styled(KBarAnimator)`
  ${({theme}) => css`
    background-color: #1a1c1e;
    max-width: 60rem;
    width: 100%;
    color: ${theme.colors.text};
    border-radius: 0.8rem;
    overflow: hidden;
    border: 0.1rem solid ${theme.colors.borderColor};

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: ${theme.colors.commandBarColor};
      -webkit-backdrop-filter: saturate(300%) blur(2.5rem);
      backdrop-filter: saturate(300%) blur(2.5rem);
    }

    & > div > div::-webkit-scrollbar {
      display: none;
    }

    & > div > div {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  `}
`
