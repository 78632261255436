import type { IArticleModel } from "@/models";
import { v4 as uuidv4 } from "uuid";

export const articles: IArticleModel[] = [
  {
    id: uuidv4(),
    language: "ptBR",
    link: "/articles/explicando-mvvm-para-uma-crianca",
    image: "/assets/images/articles/1_7AQ4ET8TFYhT7Yn5_9yTaA.jpg",
    title: "Explicando MVVM para uma criança",
    readTime: 3,
    publicationDate: "Dec 05, 2024",
    preview:
      "Sempre fiz posts sobre o desenvolvimento web, mas agora comecei uma nova fase como desenvolvedor mobile na Stone Co. Por isso, decidi compartilhar um pouco do que estou aprendendo nessa área. Esse é o primeiro post de uma série voltada para desenvolvimento mobile, principalmente com Kotlin Multiplatform (KMP). E pra começar com o pé direito, vou falar sobre um assunto essencial: a principal arquitetura usada no desenvolvimento de aplicativos.",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "/articles/as-25-palavras-reservadas-do-go",
    image: "/assets/images/articles/1_YmIExrCxkdSv2VSC_7Lu3Q.jpg",
    title: "As 25 palavras reservadas do Go",
    readTime: 4,
    publicationDate: "Jul 24, 2024",
    preview:
      "Uma das características do Go é sua simplicidade e clareza. Projetada para ser fácil de aprender e usar, a linguagem evita a complexidade excessiva encontrada em algumas outras linguagens de programação. Parte dessa simplicidade é refletida no número limitado de palavras reservadas — apenas 25. Compreender essas palavras-chave é essencial para aproveitar ao máximo o poder e a eficiência do Go. Vamos explorar cada uma dessas palavras, divididas em quatro categorias principais, para entender melhor seus usos e funções.",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "/articles/o-quanto-um-desenvolvedor-frontend-deve-conhecer-sobre-backend",
    image: "/assets/images/articles/1_j__0Mbb4qfB-jyVGdduK_Q.png",
    title: "O quanto um desenvolvedor Frontend deve conhecer sobre Backend?",
    readTime: 4,
    publicationDate: "Jul 12, 2024",
    preview:
      "No mundo do desenvolvimento web, as linhas entre frontend e backend estão cada vez mais tênues. Enquanto alguns desenvolvedores se especializam em uma dessas áreas, há uma crescente demanda por profissionais que compreendem bem ambas. Neste post, vamos explorar a importância do conhecimento de backend para desenvolvedores frontend e até que ponto esse conhecimento pode ser benéfico.",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "/articles/biome-js-um-novo-lint-e-formatter-para-javascript-e-typescript",
    image: "/assets/images/articles/1_DG7XGA9XtAGy93ZF1xQMYA.png",
    title: "Biome.js: Um novo lint e formatter para JavaScript e TypeScript",
    readTime: 4,
    publicationDate: "Jul 9, 2024",
    preview:
      "Manter um código limpo e bem formatado é essencial para a legibilidade e manutenção. Ferramentas como ESLint e Prettier têm sido os pilares dessa tarefa por anos. No entanto, uma nova ferramenta chamada Biome.js está emergindo como uma alternativa promissora oferecendo desempenho superior e uma experiência de desenvolvimento mais integrada. Neste post, vamos explorar o que é o Biome.js, seus benefícios, e como ele se compara ao ESLint e Prettier.",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "/articles/explorando-a-nova-api-use-do-react-19-simplificando-a-leitura-de-promises-e-contextos",
    image: "/assets/images/articles/1_JyJBKdgnIeYC-qnKpwbEQg.png",
    title:
      "Explorando a nova API use do React 19: Simplificando a leitura de Promises e Contextos",
    readTime: 3,
    publicationDate: "Jul 08, 2024",
    preview:
      "O React 19 introduziu a nova API use, que traz uma abordagem simplificada e flexível para lidar com Promises e Contextos dentro dos componentes React. Neste artigo, vamos explorar os detalhes dessa API, seus benefícios e como ela pode mudar o dia a dia dos desenvolvedores React",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "/articles/minha-experiencia-aprendendo-golang",
    image: "/assets/images/articles/1_44c-89qVFrs77xujlFsD8A.png",
    title: "Minha experiência aprendendo Golang",
    readTime: 4,
    publicationDate: "Jun 25, 2024",
    preview:
      "Nesse último mês tenho gastado meu tempo livre estudando Go, e nesse post quero contar um pouco como tem sido minha experiência nessa jornada de aprender uma stack nova. Antes de mais nada, o que é Go? Go, também conhecida como Golang, é uma linguagem de programação de código aberto desenvolvida pela Google",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/como-encontrar-a-arquitetura-de-software-ideal-para-o-seu-projeto-db74f2587b09",
    image: "/assets/images/articles/1_KtcvVeet8SGi2sViA1Dp2g.webp",
    title: "Como escolher a arquitetura de software ideal para o seu projeto",
    readTime: 5,
    publicationDate: "May 09, 2024",
    preview:
      "Hoje vou te mostrar uma abordagem relativamente simples, mas muito eficaz, que estou utilizando no meu dia a dia de engenheiro de software. Ela é baseada no movimento criado pelo Dan McKinley, que se chama Boring Technology. Vou te explicar como ela funciona",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/como-criar-um-monorepo-utilizando-o-turborepo-11b21a3dcfd9",
    image: "/assets/images/articles/1_Tth2JexCd2zCgZkbiP03PQ.webp",
    title: "Como criar um monorepo utilizando o Turborepo",
    readTime: 10,
    publicationDate: "Apr 24, 2024",
    preview:
      "Você já se deparou com a questão de como organizar o código-fonte de um projeto grande e complexo? Se sim, você provavelmente já ouviu falar sobre o monorepo, uma abordagem que está se tornando cada vez mais popular entre equipes de desenvolvimento de software. Mas o que exatamente é um monorepo",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/qual-stack-estou-utilizando-para-desenvolver-meus-projetos-em-2024-d8276224047f",
    image: "/assets/images/articles/1_8dCC0e8TGiZJzF7QsmrVbA.webp",
    title: "Qual stack estou utilizando para desenvolver meus projetos em 2024",
    readTime: 5,
    publicationDate: "Apr 02, 2024",
    preview:
      "Em 2024, decidi me dedicar à criação de alguns Micro-SaaS como um objetivo pessoal. Para isso, precisei escolher uma stack que me permitisse desenvolver aplicações Full Stack de forma rápida e escalável. Foi aí que encontrei a stack que vou compartilhar neste post! Mas antes de tudo",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "/articles/o-react-19-ja-esta-chegando-conheca-as-novas-features",
    image: "/assets/images/articles/1_zdJzPq7eYHQ5YoW5HfnScw.png",
    title: "O React 19 já está chegando, conheça as novas features!",
    readTime: 7,
    publicationDate: "Feb 20, 2024",
    preview:
      "O React está constantemente evoluindo, e a próxima grande atualização, a versão 19, está à vista, trazendo uma série de novidades. Neste artigo, vamos dar uma olhada nas últimas inovações que estão chegando, incluindo o React Compiler, as Actions, os React Canaries e novos hooks experimentais",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "/articles/como-integrar-a-api-da-openai-utilizando-stream-no-next-js",
    image: "/assets/images/articles/1_AaKvlQtK7PBKlqPwVPToEQ.jpg",
    title: "Como integrar a API da OpenAI utilizando stream no Next.js",
    readTime: 8,
    publicationDate: "Feb 14, 2024",
    preview:
      "Se você não vive em uma caverna e está por dentro dos assuntos mais comentados dos últimos tempos, provavelmente já ouviu falar do ChatGPT. Este é um sistema de chat que se utiliza do modelo GPT (Generative Pre-trained Transformer) da OpenAI para interagir com usuários de maneira semelhante",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/implementando-composition-pattern-em-aplicacoes-react-4e8dc92742ff",
    image: "/assets/images/articles/1_yijI7cjf8KH7S0RnGmbN2g.webp",
    title: "Implementando Composition Pattern em aplicações React",
    readTime: 4,
    publicationDate: "Jan 18, 2024",
    preview:
      "Criar e manter uma aplicação React em grande escala é sempre um desafio, por isso existe patterns que nos ajudam a organizar melhor a estrutura do nosso app e tornar ele mais fácil dar manutenção. E hoje vou te mostrar um pattern que gosto muito de utilizar nas minhas aplicações, o Composition Pattern",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/entendendo-web-workers-e-como-utiliza-los-com-react-ceb438ad9846",
    image: "/assets/images/articles/1_32sW7pGXDf7ByuJOYDnaYA.webp",
    title: "Entendendo Web Workers e como utilizá-los com React",
    readTime: 9,
    publicationDate: "Jan 09, 2024",
    preview:
      "Nos últimos anos, as aplicações web têm se tornado cada vez mais complexas e ricas em recursos, levando a uma maior demanda por processamento e gerenciamento de tarefas. Neste contexto, os Web Workers surgem como uma solução eficiente para melhorar o desempenho das aplicações",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/o-que-é-e-para-que-serve-o-cors-cross-origin-resource-sharing-64c98e6f35de",
    image: "/assets/images/articles/1_G9YX1fTo-RtZmpu-HiXjaQ.webp",
    title: "O que é, e para que serve o CORS (Cross-Origin Resource Sharing)?",
    readTime: 3,
    publicationDate: "Sep 12, 2023",
    preview:
      "CORS (Cross-Origin Resource Sharing) é um mecanismo de segurança em navegadores que restringe as solicitações HTTP de um domínio a outro domínio diferente do qual a solicitação foi originada. Em outras palavras, o CORS permite que um servidor especifique quais outros domínios podem acessar seus recursos por meio de solicitações HTTP",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/como-transformar-sua-aplicacao-web-em-um-aplicativo-mobile-em-5-minutos-241c38fda2ee",
    image: "/assets/images/articles/1_UnsvEDSUzAA8YPIQ2tMJFw.webp",
    title:
      "Como transformar sua aplicação web em um aplicativo mobile em 5 minutos",
    readTime: 7,
    publicationDate: "Sep 08, 2023",
    preview:
      "Não, você não leu errado, vou te ensinar a como transformar uma aplicação web em uma aplicação mobile, e não é PWA (Progressive web app), para isso, vamos utilizar o Capacitor. O Capacitor é um runtime nativo multiplataforma que facilita a criação de aplicativos móveis de alto desempenho que funcionam nativamente no iOS, Android e em outras plataformas",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/como-construir-aplica%C3%A7%C3%B5es-web-mais-r%C3%A1pidas-acess%C3%ADveis-e-sustent%C3%A1veis-be6520bd75a2",
    image: "/assets/images/articles/1_ff7jRjN8UlRWklaL4UnFLg.png",
    title:
      "Como construir aplicações web mais rápidas, acessíveis e sustentáveis",
    readTime: 3,
    publicationDate: "Sep 01, 2023",
    preview:
      "Como desenvolvedor, a preocupação com o desempenho, acessibilidade e sustentabilidade dos nossos projetos é essencial para entregar experiências de alta qualidade para os usuários. Neste artigo, vou compartilhar algumas ferramentas valiosas que uso no meu dia a dia para monitorar e melhorar esses aspectos em meus sites.",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/como-melhorar-o-desempenho-de-aplica%C3%A7%C3%B5es-react-48c2c4b5a8fa",
    image: "/assets/images/articles/1_Mj_fb2oR6IC0HsXYnHgQPg.jpg",
    title: "Como melhorar o desempenho de aplicações React com Code Splitting",
    readTime: 3,
    publicationDate: "May 15, 2023",
    preview:
      "O desempenho é um fator crítico no sucesso de qualquer aplicação web. Uma aplicação rápida e responsiva pode proporcionar uma excelente experiência para o seu usuário, enquanto uma aplicação lenta pode gerar frustrações. Uma abordagem importante para melhorar o desempenho de suas aplicações React é o Code Splitting",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/o-que-s%C3%A3o-react-server-components-44033169b664",
    image: "/assets/images/articles/1_d94y0aTYOs4DWCoL07rwRw.jpg",
    title: "O que são React Server Components?",
    readTime: 6,
    publicationDate: "May 8, 2023",
    preview:
      "Nos últimos dias o React Server Components vem sendo bem falado pela comunidade em geral, mas você sabe o que é isso, para que serve e qual dor ele vem resolver? Vem comigo que vou te explicar tudo o que você precisa saber sobre o RSC. Antes de mais nada, o que é React Server Componentes? O React Server Components (RSC) permite que o servidor e o cliente (navegador) trabalhem juntos na renderização",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/hooks-que-talvez-voc%C3%AA-n%C3%A3o-conhe%C3%A7a-no-react-2bd4f013a49e",
    image: "/assets/images/articles/1_8_sdXNNgbpLBXIek4-Z_ig.jpg",
    title: "10 hooks do React que você precisa conhecer",
    readTime: 5,
    publicationDate: "Apr 19, 2023",
    preview:
      "O React é uma biblioteca JavaScript amplamente usada para criar interfaces de usuário. Uma das suas características mais valiosas é o sistema de Hooks, que permite o uso de estado e outros recursos do React sem a necessidade de escrever uma classe. Embora o useState e o useEffect sejam os Hooks mais conhecidos, o React oferece uma variedade de Hooks menos comuns que podem ser extremamente úteis",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/como-melhorar-a-acessibilidade-em-aplica%C3%A7%C3%B5es-react-c58d96c79e97",
    image: "/assets/images/articles/1_uGE8tKA06kSJbYNNtyhS6Q.jpg",
    title: "Como melhorar a acessibilidade em aplicações React",
    readTime: 6,
    publicationDate: "Apr 10, 2023",
    preview:
      "A acessibilidade é uma parte essencial do desenvolvimento de aplicações web, garantindo que todos os usuários, incluindo aqueles com deficiências, possam utilizar nossos produtos de forma eficiente e eficaz. Neste post, abordarei algumas técnicas para aplicar acessibilidade em aplicações React. Vamos ver exemplos práticos de como tornar nossas aplicações mais inclusivas",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/monitorando-as-atividades-dos-seus-usuarios-usando-o-microsoft-clarity-em-aplicacoes-react-4eb376eef727",
    image: "/assets/images/articles/1_HDpYsSxV3SGffs5q69iIxQ.jpg",
    title:
      "Monitorando as atividades dos seus usuários usando o Microsoft Clarity em aplicações React",
    readTime: 5,
    publicationDate: "Dec 15, 2022",
    preview:
      "Como disse o Nadella, manter a atenção e o engajamento do usuário é uma commodity extremamente valiosa, e como fazer isso? Um dos principais pontos é entender qual é o comportamento do seu usuário! Coletar dados de como o usuário está se comportando dentro da sua aplicação é uma ferramenta poderosa para decisões de experiência do usuário e marketing",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/arquitetura-para-aplicacoes-react-d77361bf2d49",
    image: "/assets/images/articles/1_KhAwZR9hPYSKZrxLHNmRAQ.jpg",
    title: "Arquitetura para aplicações React v2",
    readTime: 5,
    publicationDate: "Dec 5, 2022",
    preview:
      "A aproximadamente um ano atrás fiz um post falando sobre como eu criava a arquitetura das aplicações em React, porém já se passo um bom tempo, e já estava na hora de fazer um update naquela estrutura. Esse post é para te mostrar a nova maneira como estou criando minhas aplicações React",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/pare-de-usar-vari-c3-a1veis-de-ambiente-do-jeito-errado-d91fba0d4188",
    image: "/assets/images/articles/1_cXpYpOzPie_hHvNikUNjfQ.jpg",
    title: "Pare de usar variáveis de ambiente do jeito errado",
    readTime: 3,
    publicationDate: "Aug 8, 2022",
    preview:
      "Algum dia você já ficou procurando o que estava quebrando sua aplicação, e depois de um bom tempo descobriu que esqueceu de alguma variável de ambiente? Eu já, várias e várias vezes… Hoje venho te mostrar como acabar de vez com esse problema",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/criando-uma-biblioteca-de-design-system-utilizando-react-214f31219a33",
    image: "/assets/images/articles/1_8_sdXNNgbpLBXIek2-Z_ig.jpg",
    title: "Criando uma biblioteca de Design System utilizando React",
    readTime: 10,
    publicationDate: "Mar 24, 2022",
    preview:
      "Design System é um documento vivo com todos os componentes e suas variações de um produto ou serviço, para facilitar tanto a comunicação entre times como o desenvolvimento final do produto. Ele facilita a manter a constância do design do produto. A ideia é que",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/o-que-s%C3%A3o-micro-frontends-5e83b91ad45d",
    image: "/assets/images/articles/1_l3j3Mos25Enb3FDsK8n2ew.jpg",
    title: "O que são Micro-frontends?",
    readTime: 4,
    publicationDate: "Mar 7, 2022",
    preview:
      "A algum tempo atrás, estávamos acostumados a criar aplicações monolíticas, ou seja, o frontend e o backend ficavam na mesma camada. E com a evolução da área surgiu os micro-serviços, onde conseguimos criar soluções independentes",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/voc%C3%AA-ainda-usa-create-react-app-em-2022-3434aff3d644",
    image: "/assets/images/articles/1_xJknqmZzptRvuQRZp_nFCg.jpg",
    title: "Você ainda usa CREATE-REACT-APP em 2022?",
    readTime: 4,
    publicationDate: "Feb 9, 2022",
    preview:
      "Se você ainda usa CRA para criar suas aplicações React, eu tenho uma alternativa que pode ser melhor! VITE é uma ferramenta de construção que visa fornecer uma experiência de desenvolvimento mais rápida e enxuta para projetos webs",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/arquitetando-uma-aplica%C3%A7%C3%A3o-next-js-4e30f45d69ac",
    image: "/assets/images/articles/1_QE5LF_VxUmR-CGiFLpCzIQ.jpg",
    title: "Arquitetura para aplicações Next.js",
    readTime: 4,
    publicationDate: "Nov 11, 2021",
    preview:
      "Recentemente fiz um post mostrando como monto a arquitetura das minhas aplicações utilizando React, se você não viu da uma olhada aqui, vai curtir bastante! E algumas pessoas me perguntaram como eu fazia com o",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/feature-escondida-do-navegador-que-pode-mudar-como-voc%C3%AA-desenvolve-sua-ui-83d6044d850f",
    image: "/assets/images/articles/1_jtw_eupkm_Qtn6IAOLFt5A.jpg",
    title:
      "Feature escondida do navegador que pode mudar como você desenvolve sua UI",
    readTime: 2,
    publicationDate: "Nov 8, 2021",
    preview:
      "Recentemente descobri essa feature que tem otimizado meu trabalho durante o desenvolvimento de uma aplicação que é o document.designMode, se você também não conhece, se liga em um exemplo de como ela funciona",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "/articles/refresh-token-com-axios-interceptors-e-fila-de-requisicoes",
    image: "/assets/images/articles/1_Xpjqhhs86WgB0STTJ25Yxg.jpg",
    title: "Refresh Token com Axios Interceptors e fila de requisições",
    readTime: 3,
    publicationDate: "Oct 27, 2021",
    preview:
      "A um tempo atrás eu estava com problemas de refreshToken na aplicação de um cliente, o que acontecia era que a página Dashboard chamava 3 endpoints diferentes, e quando dava erro 401 (Unauthorized), meu interceptor que criei dentro",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/root-import-com-react-e-typescript-8ad7259d87cc",
    image: "/assets/images/articles/1_VfdEGmREkl7hOy7LEBjOvQ.jpg",
    title: "Root import com React e TypeScript",
    readTime: 3,
    publicationDate: "Oct 19, 2021",
    preview:
      "Aposto que você já se perdeu nos caminhos relativos na hora de importar algo em sua aplicação certo? Então hoje vou te mostrar como resolvi esse problema nas minhas aplicações e nunca mais precisei perder tempo descobrindo",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "/articles/arquitetando-uma-aplicacao-react",
    image: "/assets/images/articles/1_V1tpiEKk811eQ7Z6Q66wmQ.jpg",
    title: "Arquitetura para aplicações React",
    readTime: 4,
    publicationDate: "Oct 7, 2021",
    preview:
      "Hoje vou mostrar como crio a arquitetura das minhas aplicações front-end utilizando React, escalável e de uma forma organizada para ser de fácil entendimento e manutenção do código.",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/criando-uma-documenta%C3%A7%C3%A3o-para-aplica%C3%A7%C3%B5es-frontend-56eab8438f60",
    image: "/assets/images/articles/1_Bk_VMdorAo9NB3YhJ8K1Rg.jpg",
    title: "Criando uma documentação com StoryBook para React",
    readTime: 4,
    publicationDate: "May 26, 2021",
    preview:
      "Hoje vou te mostrar uma ferramenta que vem ganhando bastante espaço na comunidade e no mercado, que é ninguém menos que o Storybook, uma ferramenta Open Source que prepara um",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/arquitetura-de-projetos-react-com-diferentes-m%C3%B3dulos-66cd569fa7ac",
    image: "/assets/images/articles/1_51Qr8rhxGIjEKVtYm5sW5A.jpg",
    title: "Arquitetura de projetos React com diferentes módulos",
    readTime: 4,
    publicationDate: "Nov 24, 2020",
    preview:
      "Por ser uma lib, o React não cria muitas regras em como você deve organizar e estruturar seus projetos. E isso é muito legal, nos da a liberdade de criar como nós quisermos",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/dark-mode-com-react-js-e-styled-components-925d75fef510",
    image: "/assets/images/articles/1_ngbKsfIURp6_RFrubMbDZw.jpg",
    title: "Dark Mode com React JS e Styled Components",
    readTime: 3,
    publicationDate: "Jul 25, 2020",
    preview:
      "Fala devs, beleza? Hoje vou ensinar para vocês um jeito bem simples de como criar o Dark Mode na sua aplicação React JS utilizando apenas Styled Components",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/como-criar-aliases-de-e-mails-gratuitos-para-seu-dom%C3%ADnio-7dfd52fdcadd",
    image: "/assets/images/articles/1_p76i7M58S6qBOAA6xhnqDg.jpg",
    title: "Como criar email forwarding gratuitos para seu domínio",
    readTime: 3,
    publicationDate: "Jul 17, 2020",
    preview:
      "Esses dias fiz um post sobre a hospedagem da Vercel, ensinando como fazer deploy de uma aplicação que você pode ver por aqui. Durante o meu uso do dia a dia, senti falta de um serviço de e-mail personalizado com o meu domínio, por exemplo",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/a-melhor-documenta%C3%A7%C3%A3o-do-mundo-3f9b6fc981",
    image: "/assets/images/articles/1_XMu-qLlss3aBJAjXtqO0Vg.jpg",
    title: "A melhor documentação do mundo!",
    readTime: 2,
    publicationDate: "Jul 15, 2020",
    preview:
      "Hoje vou compartilhar com vocês uma dica incrível de como usar o GitHub como documentação",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/a-melhor-hospedagem-gr%C3%A1tis-para-o-seu-front-end-657c939bf0d4",
    image: "/assets/images/articles/1_O7oeyGq3Gjq42NKpIfJqjg.jpg",
    title: "A melhor hospedagem grátis para o seu Front-End",
    readTime: 3,
    publicationDate: "Jul 11, 2020",
    preview:
      "Sempre usei os serviços da Hostinger Brasil para hospedar minhas aplicações, e nunca tive problemas, porém fiz uma nova versão do meu site pessoal utilizando React, e senti a falta de um deploy automático",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/refresh-token-usando-axios-interceptors-74d2a64106e8",
    image: "/assets/images/articles/1_r7uiurVtu3QP6Mak3QkEcw.jpg",
    title: "Refresh Token usando Axios Interceptors",
    readTime: 2,
    publicationDate: "Jul 10, 2020",
    preview:
      "Axios é um cliente HTTP, que funciona tanto no browser quanto em Node.Js. A biblioteca é basicamente uma API que sabe interagir tanto com XMLHttpRequest quanto com a interface HTTP do N",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/o-dia-em-que-transformei-uma-caixa-cinza-em-um-jogo-2d-66d8499fd908",
    image: "/assets/images/articles/1_o9bphVNIjIBtVxIOFhfVjQ.jpg",
    title: "O dia em que transformei uma caixa cinza em um jogo 2D",
    readTime: 2,
    publicationDate: "Jun 28, 2020",
    preview:
      "Durante essa semana participei da #ImersaoGameDev da Alura. Foram 5 dias em que o Guilherme Lima, Paulo Silveira e a Juliana Negreiros se dedicar",
  },
  {
    id: uuidv4(),
    language: "ptBR",
    link: "https://medium.com/@vinniciusgomes/a-extens%C3%A3o-que-todo-front-end-deveria-conhecer-17acb53b0912",
    image: "/assets/images/articles/1_iXkiSiB9ovSzN_z7AVKdxw.jpg",
    title: "A extensão que todo Front End deveria conhecer",
    readTime: 2,
    publicationDate: "Jun 23, 2020",
    preview:
      "Esses dias me deparei com a extensão VisBug achei tão incrível que resolvi compartilhar, ela trás diversos design debug tools para o navegador como",
  },
];
