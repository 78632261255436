import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";

function VercelHoCProvider({ children }: { children: React.ReactNode }) {
  return (
    <>
      <VercelAnalytics />
      <SpeedInsights />
      {children}
    </>
  );
}

export { VercelHoCProvider };
