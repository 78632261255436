import styled, {css} from 'styled-components'

type IProgressBarProps = {
  width: number
}

export const ProgressContainer = styled.div`
  width: 100%;
  height: 0.3rem;
  background: transparent;
`

export const ProgressBar = styled.div<IProgressBarProps>`
  ${({theme, width}) => css`
    height: 0.4rem;
    background: ${theme.colors.highlightColor};
    width: ${width || 0}%;
    border-radius: ${width === 100 ? 0 : '0 0.4rem 0.4rem 0'};
  `}
`

export const ProgressText = styled.span`
  font-size: 1.6rem;
`
