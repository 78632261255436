import {Info} from 'lucide-react'
import {memo} from 'react'

import {Text} from '../'
import type {ITextAreaProps} from './interfaces'
import * as S from './styles'

const TextArea = ({label, errorMessage, ...rest}: ITextAreaProps) => {
  return (
    <S.Container hasError={!!errorMessage}>
      <Text>{label}</Text>
      <textarea data-testId="text-area" {...rest} />

      {errorMessage && (
        <S.ErrorWrapper data-testId="error-message">
          <Info size={14}/>

          <Text size="small" color="red">
            {errorMessage}
          </Text>
        </S.ErrorWrapper>
      )}
    </S.Container>
  )
}

export default memo(TextArea)
