import {Tooltip} from '@radix-ui/themes'
import {MoonIcon, SunIcon} from '@/assets/icons'
import {useTheme} from '@/contexts'
import {useAnalytics} from '@/hooks'
import {memo} from 'react'

import type {IToggleThemeButtonProps} from './interfaces'
import * as S from './styles'

const ToggleThemeButton = ({
                             selectedTheme,
                             toggleTheme,
                           }: IToggleThemeButtonProps) => {
  const {trackEvent} = useAnalytics()
  const {theme} = useTheme()

  return (
    <>
      {selectedTheme === 'dark' ? (
        <Tooltip content="Switch to light mode">
          <S.Button
            onClick={() => {
              trackEvent('header.toggle.theme', {
                theme: 'light',
              })
              toggleTheme()
            }}
            aria-label="Light mode"
          >
            <SunIcon color={theme.colors.title}/>
          </S.Button>
        </Tooltip>
      ) : (
        <Tooltip content="Switch to dark mode">
          <S.Button
            onClick={() => {
              trackEvent('header.toggle.theme', {
                theme: 'dark',
              })
              toggleTheme()
            }}
            aria-label="Dark mode"
          >
            <MoonIcon color={theme.colors.title}/>
          </S.Button>
        </Tooltip>
      )}
    </>
  )
}

export default memo(ToggleThemeButton)
