import styled, {css} from 'styled-components'

type IContainerProps = {
  hasError?: boolean
}

export const Container = styled.div<IContainerProps>`
  ${({theme, hasError}) => css`
    width: 100%;
    display: grid;
    gap: 0.8rem;

    input {
      width: 100%;
      padding: 1.6rem;
      font-family: monospace !important;
      height: 5.6rem;
      border-radius: 0.8rem;
      background-color: transparent;
      border: 0.1rem solid ${hasError ? 'red' : theme.colors.borderColor};
      color: ${theme.colors.title};
      font-size: 1.6rem;
      transition: all 0.3s ease-in-out;

      &:focus,
      &:hover {
        border-color: ${theme.colors.title};
      }
    }
  `}
`

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  svg {
    color: red;
  }
`
