import {useTheme} from '@/hooks'
import {DefaultSeo, SocialProfileJsonLd} from 'next-seo'
import {memo} from 'react'

import type {ISeoProps} from './interfaces'

const DOMAIN = 'https://vinniciusgomes.dev'
const DEFAULT_OG_IMAGE = 'https://vinniciusgomes.dev/assets/images/cover.png'

function Seo({
               title = 'Vinnicius Gomes — Senior Software Engineer',
               description = "My name is Vinnicius Gomes, a software engineer passionate about traveling, finances, mountain biker, and reading. I'm 24 years old, married, and based in São José dos Campos, São Paulo.",
               siteName = 'Vinnicius Gomes — Senior Software Engineer',
               canonical = DOMAIN,
               ogImage = DEFAULT_OG_IMAGE,
               themeColor,
               ogType = 'website',
               twitterHandle = '@vinniciusgomees',
               locale = 'pt_BR',
               keywords,
             }: ISeoProps) {
  const {theme} = useTheme()

  return (
    <>
      <DefaultSeo
        title={title}
        description={description}
        canonical={canonical}
        openGraph={{
          type: ogType,
          locale: locale,
          url: canonical,
          title: title,
          description: description,
          site_name: siteName,
          images: [
            {
              url: ogImage,
              width: 1920,
              height: 1229,
              alt: title,
            },
          ],
        }}
        twitter={{
          handle: twitterHandle,
          site: twitterHandle,
          cardType: 'summary_large_image',
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: keywords ? keywords : '',
          },
          {
            name: 'author',
            content: siteName,
          },
          {
            name: 'theme-color',
            content: theme.colors.background || themeColor || '#09090B',
          },
          {
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'black-translucent',
          },
          {
            name: 'robots',
            content: 'index,follow',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'sitemap',
            type: 'application/xml',
            href: `${DOMAIN}/sitemap.xml`,
          },
          {
            rel: 'shortcut icon',
            href: '/assets/images/favicon/favicon.ico',
          },
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/assets/images/favicon/apple-touch-icon.png',
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/assets/images/favicon/favicon-32x32.png',
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: '/assets/images/favicon/favicon-16x16.png',
          },
          {
            rel: 'manifest',
            href: '/assets/images/favicon/site.webmanifest',
          },
        ]}
      />
      <SocialProfileJsonLd
        type="Person"
        name="Vinnicius Gomes"
        url="http://vinniciusgomes.dev"
        sameAs={[
          'https://www.instagram.com/vinniciusgomees',
          'https://www.linkedin.com/in/vinniciusgomes',
          'https://github.com/vinniciusgomes',
        ]}
      />
    </>
  )
}

export default memo(Seo)
