import type { IClientModel } from "@/models";
import { v4 as uuidv4 } from "uuid";

export const clients: IClientModel[] = [
  {
    id: uuidv4(),
    name: "CEGEMESP",
    image: "/assets/images/clients/cegemesp.png",
    link: "https://cegemesp.com.br/",
    lastProjectYear: 2024,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Health",
  },
  {
    id: uuidv4(),
    name: "Stone",
    image: "/assets/images/clients/stone.svg",
    link: "https://www.stone.com.br/",
    lastProjectYear: 2024,
    featuredClient: true,
    country: "Brazil",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "Ton",
    image: "/assets/images/clients/ton.svg",
    link: "https://www.ton.com.br/",
    lastProjectYear: 2024,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "Smart Leilões",
    image: "/assets/images/clients/smart-leiloes.svg",
    link: "https://teamsmartleiloes.com.br/",
    lastProjectYear: 2024,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Real Estate",
  },
  {
    id: uuidv4(),
    name: "Blombô",
    image: "/assets/images/clients/blombo.svg",
    link: "https://blombo.com/",
    lastProjectYear: 2024,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Marketplace",
  },
  {
    id: uuidv4(),
    name: "Almeida & Dale",
    image: "/assets/images/clients/almeidaedale.svg",
    link: "https://www.almeidaedale.com.br/",
    lastProjectYear: 2024,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Art Gallery",
  },
  {
    id: uuidv4(),
    name: "Bergamía",
    image: "/assets/images/clients/bergamia.avif",
    link: "https://bergamia.com.br/",
    lastProjectYear: 2024,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Ecommerce",
  },
  {
    id: uuidv4(),
    name: "Conserva",
    image: "/assets/images/clients/conserva.svg",
    link: "https://conserva.org/",
    lastProjectYear: 2024,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Sustainability",
  },
  {
    id: uuidv4(),
    name: "IT Lean",
    image: "/assets/images/clients/itlean.svg",
    link: "https://itlean.com.br",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Event",
  },
  {
    id: uuidv4(),
    name: "Líber",
    image: "/assets/images/clients/liber.svg",
    imageDark: "/assets/images/clients/liber-dark.svg",
    link: "https://libercapital.com.br",
    lastProjectYear: 2023,
    featuredClient: true,
    country: "Brazil",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "LiveFarm",
    image: "/assets/images/clients/livefarm.svg",
    link: "https://livefarm.com.br",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Agriculture",
  },
  {
    id: uuidv4(),
    name: "SSV",
    image: "/assets/images/clients/ssv.svg",
    link: "https://ssv.me",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Latin America",
    marketArea: "Venture capital",
  },
  {
    id: uuidv4(),
    name: "INWI",
    image: "/assets/images/clients/inwi.svg",
    link: "https://inwi.com.br",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Consulting",
  },
  {
    id: uuidv4(),
    name: "Flower Bar",
    image: "/assets/images/clients/flowerbar.svg",
    link: "https://instagram.com/flowerbarsp/",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Florist",
  },
  {
    id: uuidv4(),
    name: "Alix Rio",
    image: "/assets/images/clients/alix.svg",
    link: "https://alixrio.com",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Ecommerce",
  },
  {
    id: uuidv4(),
    name: "7the Experience",
    image: "/assets/images/clients/7the.svg",
    link: "https://7the.com.br",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Event",
  },
  {
    id: uuidv4(),
    name: "Woney",
    image: "/assets/images/clients/woney.svg",
    link: "https://woney.com.br",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "Farfarm",
    image: "/assets/images/clients/farfarm.svg",
    link: "http://farfarm.co",
    lastProjectYear: 2022,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Sustainability",
  },
  {
    id: uuidv4(),
    name: "Proeza Design Studio",
    image: "/assets/images/clients/proeza.svg",
    link: "https://proezastudio.com",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Design",
  },
  {
    id: uuidv4(),
    name: "Receba",
    image: "/assets/images/clients/receba.svg",
    link: "https://www.receba.app",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "Banco PAN",
    image: "/assets/images/clients/pan.svg",
    imageDark: "/assets/images/clients/pan-dark.svg",
    link: "https://www.bancopan.com.br",
    lastProjectYear: 2023,
    featuredClient: true,
    country: "Brazil",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "BTG Pactual",
    image: "/assets/images/clients/btg.svg",
    imageDark: "/assets/images/clients/btg-dark.svg",
    link: "https://www.btgpactual.com",
    lastProjectYear: 2022,
    featuredClient: true,
    country: "Latin America",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "Santander",
    image: "/assets/images/clients/santander.svg",
    link: "https://santander.com",
    lastProjectYear: 2021,
    featuredClient: true,
    country: "Global",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "Getnet",
    image: "/assets/images/clients/getnet.svg",
    link: "https://www.getneteurope.com",
    lastProjectYear: 2021,
    featuredClient: false,
    country: "Global",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "PagoNxt",
    image: "/assets/images/clients/pagonxt.svg",
    imageDark: "/assets/images/clients/pagonxt-dark.svg",
    link: "https://www.pagonxt.com",
    lastProjectYear: 2021,
    featuredClient: false,
    country: "Latin America | Eurozone | UK | USA",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "Auttar",
    image: "/assets/images/clients/auttar.svg",
    link: "https://www.auttar.com.br",
    lastProjectYear: 2021,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "VIFE Solutions",
    image: "/assets/images/clients/vifesolutions.svg",
    link: "https://vifesolutions.com",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "Camila Bossolan",
    image: "/assets/images/clients/camila-bossolan.svg",
    link: "http://www.camilabossolan.com.br",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Design",
  },
  {
    id: uuidv4(),
    name: "IBM",
    image: "/assets/images/clients/ibm.svg",
    link: "https://www.ibm.com",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Global",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "UOL",
    image: "/assets/images/clients/uol.svg",
    imageDark: "/assets/images/clients/uol-dark.svg",
    link: "https://uol.com.br",
    lastProjectYear: 2021,
    featuredClient: true,
    country: "Brazil",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "Gov.br",
    image: "/assets/images/clients/govbr.svg",
    link: "https://www.gov.br",
    lastProjectYear: 2018,
    featuredClient: true,
    country: "Brazil",
    marketArea: "Government",
  },
  {
    id: uuidv4(),
    name: "UN",
    image: "/assets/images/clients/un.svg",
    link: "https://www.un.org",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Global",
    marketArea: "Government",
  },
  {
    id: uuidv4(),
    name: "Johnson & Johnson",
    image: "/assets/images/clients/jnj.svg",
    link: "https://www.jnj.com",
    lastProjectYear: 2019,
    featuredClient: false,
    country: "Global",
    marketArea: "Healthcare",
  },
  {
    id: uuidv4(),
    name: "Heineken",
    image: "/assets/images/clients/heineken.svg",
    link: "https://www.heineken.com",
    lastProjectYear: 2018,
    featuredClient: true,
    country: "Global",
    marketArea: "Drink",
  },
  {
    id: uuidv4(),
    name: "Porto Seguro",
    image: "/assets/images/clients/porto-seguro.svg",
    imageDark: "/assets/images/clients/porto-seguro-dark.svg",
    link: "https://www.portoseguro.com.br",
    lastProjectYear: 2020,
    featuredClient: true,
    country: "Brazil",
    marketArea: "Insurance",
  },
  {
    id: uuidv4(),
    name: "Ultragaz",
    image: "/assets/images/clients/ultragaz.svg",
    link: "https://www.ultragaz.com.br",
    lastProjectYear: 2019,
    featuredClient: true,
    country: "Brazil",
    marketArea: "Energy",
  },
  {
    id: uuidv4(),
    name: "Sensedia",
    image: "/assets/images/clients/sensedia.svg",
    link: "https://sensedia.com",
    lastProjectYear: 2019,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "Cateno",
    image: "/assets/images/clients/cateno.svg",
    link: "https://www.cateno.com.br/",
    lastProjectYear: 2019,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "Quinto Andar",
    image: "/assets/images/clients/quinto-andar.svg",
    link: "https://www.quintoandar.com.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Real Estate",
  },
  {
    id: uuidv4(),
    name: "Provi",
    image: "/assets/images/clients/provi.svg",
    link: "https://provi.com.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Finance",
  },
  {
    id: uuidv4(),
    name: "Coren",
    image: "/assets/images/clients/coren.svg",
    link: "https://coren.digital",
    lastProjectYear: 2023,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "iClubs",
    image: "/assets/images/clients/iclubs.svg",
    link: "https://www.iclubs.tech",
    lastProjectYear: 2022,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "Zappts",
    image: "/assets/images/clients/zappts.svg",
    link: "https://www.zappts.com.br",
    lastProjectYear: 2019,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "Algar Telecom",
    image: "/assets/images/clients/algar.svg",
    link: "https://www.algartelecom.com.br",
    lastProjectYear: 2019,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Telecom",
  },
  {
    id: uuidv4(),
    name: "St. Marche",
    image: "/assets/images/clients/st-marche.svg",
    link: "https://www.marche.com.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Supermarket",
  },
  {
    id: uuidv4(),
    name: "INPE",
    image: "/assets/images/clients/inpe.svg",
    link: "http://www.inpe.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Government",
  },
  {
    id: uuidv4(),
    name: "CPTEC",
    image: "/assets/images/clients/cptec.png",
    link: "https://www.cptec.inpe.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Government",
  },
  {
    id: uuidv4(),
    name: "Macromakers",
    image: "/assets/images/clients/macromakers.svg",
    link: "https://macromakers.pt",
    lastProjectYear: 2022,
    featuredClient: false,
    country: "Portugal",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "Cunho Portugues",
    image: "/assets/images/clients/cunho-portugues.svg",
    link: "https://cunhoportugues.pt",
    lastProjectYear: 2022,
    featuredClient: false,
    country: "Portugal",
    marketArea: "Ecommerce",
  },
  {
    id: uuidv4(),
    name: "Unisal",
    image: "/assets/images/clients/unisal.svg",
    link: "http://unisal.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Education",
  },
  {
    id: uuidv4(),
    name: "Hype Games",
    image: "/assets/images/clients/hype.svg",
    link: "https://hype.games",
    lastProjectYear: 2021,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Games",
  },
  {
    id: uuidv4(),
    name: "Level UP",
    image: "/assets/images/clients/level-up.svg",
    link: "https://leveluplatam.com",
    lastProjectYear: 2021,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Games",
  },
  {
    id: uuidv4(),
    name: "HBO",
    image: "/assets/images/clients/hbo.svg",
    link: "https://www.hbo.com",
    lastProjectYear: 2021,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Entertainment",
  },
  {
    id: uuidv4(),
    name: "Editora do Brasil",
    image: "/assets/images/clients/editoradobrasil.svg",
    link: "https://www.editoradobrasil.com.br",
    lastProjectYear: 2019,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Education",
  },
  {
    id: uuidv4(),
    name: "Romasoft Tecnologia",
    image: "/assets/images/clients/romasoft.svg",
    link: "http://romasofttecnologia.com.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "Devmedia",
    image: "/assets/images/clients/devmedia.svg",
    link: "https://www.devmedia.com.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "Folheto na mão",
    image: "/assets/images/clients/folhetonamao.svg",
    link: "https://folhetonamao.com.br",
    lastProjectYear: 2021,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Ecommerce",
  },
  {
    id: uuidv4(),
    name: "Netlit",
    image: "/assets/images/clients/netlit.svg",
    link: "https://www.netlit.com.br",
    lastProjectYear: 2019,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Education",
  },
  {
    id: uuidv4(),
    name: "Interagir",
    image: "/assets/images/clients/interagir.svg",
    link: "https://interagir.co",
    lastProjectYear: 2021,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Technology",
  },
  {
    id: uuidv4(),
    name: "Agenda4Pets",
    image: "/assets/images/clients/agenda4pets.svg",
    link: "https://www.agenda4pets.com.br",
    lastProjectYear: 2019,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Pets",
  },
  {
    id: uuidv4(),
    name: "Dra. Layse Schuster",
    image: "/assets/images/clients/layse-schuster.svg",
    link: "http://dralayse.com.br",
    lastProjectYear: 2019,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Health",
  },
  {
    id: uuidv4(),
    name: "Value & Care",
    image: "/assets/images/clients/value-and-care.svg",
    link: "http://valueandcare.com.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Health",
  },
  {
    id: uuidv4(),
    name: "Rede Passos",
    image: "/assets/images/clients/rede-passos.svg",
    link: "https://clubepassos.com.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Energy",
  },
  {
    id: uuidv4(),
    name: "Keromais",
    image: "/assets/images/clients/keromais.svg",
    link: "https://www.padariakeromais.com.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Bakery",
  },
  {
    id: uuidv4(),
    name: "Teocenter",
    image: "/assets/images/clients/teocenter.svg",
    link: "https://www.teocenter.com.br",
    lastProjectYear: 2018,
    featuredClient: false,
    country: "Brazil",
    marketArea: "Ecommerce",
  },
];
