import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 2.4rem;
  border: 0.1rem solid ${({theme}) => theme.colors.borderColor};
  border-radius: 0.8rem;
`

export const RevealEffect = styled.div`
  position: absolute;
  inset: -2px;
  border-radius: 16px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
`
