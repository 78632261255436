import {Tooltip} from '@radix-ui/themes'
import {ToggleThemeButton} from '@/components'
import {Logo} from '@/components/Logo'
import {useTheme} from '@/contexts'
import {motion} from 'framer-motion'
import {useAnalytics} from '@/hooks'
import {useKBar} from 'kbar'
import {Command} from 'lucide-react'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {memo, useEffect, useState} from 'react'
import {THEMES_KEYS} from '@/styles/themes'

import * as S from './styles'

function Header() {
  const [hovered, setHovered] = useState('About')
  const [selected, setSelected] = useState('')
  const [showDrawer, setShowDrawer] = useState(false)
  const [scrolling, setScrolling] = useState(false)
  const {toggleTheme, keyTheme, theme} = useTheme()
  const {trackEvent} = useAnalytics()
  const {query} = useKBar()
  const router = useRouter()

  const THEME_LIGHT_SELECTED = keyTheme === THEMES_KEYS.LIGHT_THEME

  const handleOpenKBar = () => {
    query.toggle()
    trackEvent('header.open.kbar')
  }

  const handleOpenDrawer = () => {
    setShowDrawer(prev => !prev)
    trackEvent(showDrawer ? 'header.close.drawer' : 'header.open.drawer')
  }

  useEffect(() => {
    setSelected(`/${router.pathname.split('/')[1]}`)
    setHovered('')
  }, [router])

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0
      setScrolling(isScrolled)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const variant = showDrawer ? 'opened' : 'closed'
  const top = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: 45,
      translateY: 2,
    },
  }
  const center = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  }
  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: -45,
      translateY: -2,
    },
  }
  const unitHeight = 4
  const unitWidth = (unitHeight * (20 as number)) / (10 as number)
  const lineProps = {
    stroke: theme.colors.title,
    strokeWidth: 2 as number,
    vectorEffect: 'non-scaling-stroke',
    initial: 'closed',
    animate: variant,
    transition: {type: 'spring', stiffness: 260, damping: 20},
  }

  const PAGES_PATH = [
    {
      label: 'About',
      path: '/about',
    },
    {
      label: 'Articles',
      path: '/articles',
    },
    {
      label: 'Projects',
      path: '/projects',
    },
    {
      label: 'Companies',
      path: '/companies',
    },
    {
      label: 'Stack',
      path: '/stack',
    },
    {
      label: 'Reading',
      path: '/highlights',
    },
    {
      label: 'Contact',
      path: '/contact',
    },
    {
      label: 'Resume',
      path: '/resume',
    },
  ]

  return (
    <>
      <S.Container scrolling={scrolling}>
        <S.LogoWrapper>
          <Tooltip content="Back to home">
            <div>
              <S.Logo selected={selected === '/'}>
                <Link href="/" aria-label="VG">
                  <Logo height={14}/>
                </Link>
              </S.Logo>
            </div>
          </Tooltip>
        </S.LogoWrapper>
        <S.Content>
          <S.Navigation>
            {PAGES_PATH.map(page => {
              const {path, label} = page
              const isHovered = hovered === label

              return (
                <li key={path}>
                  <Link href={path} aria-label={label}>
                    <S.NavigationItem
                      onHoverStart={() => setHovered(label)}
                      onHoverEnd={() => setHovered('')}
                      selected={selected === path}
                    >
                      <span>{label}</span>

                      {isHovered && (
                        <S.NavigationItemHover
                          layoutId="nav"
                          initial={{opacity: 0}}
                          animate={{opacity: 0.8}}
                          exit={{opacity: 0}}
                        />
                      )}
                    </S.NavigationItem>
                  </Link>
                </li>
              )
            })}
          </S.Navigation>
        </S.Content>

        <S.ToggleWrapper>
          <ToggleThemeButton
            selectedTheme={THEME_LIGHT_SELECTED ? 'light' : 'dark'}
            toggleTheme={toggleTheme}
          />

          <Tooltip content="Open search">
            <S.KbarButton
              onClick={handleOpenKBar}
              aria-label="Command bar button"
            >
              <S.Kbd>
                <Command size={16}/>
              </S.Kbd>
            </S.KbarButton>
          </Tooltip>
        </S.ToggleWrapper>

        <S.DrawerMenuButton onClick={handleOpenDrawer}>
          <motion.svg
            viewBox={`0 0 ${unitWidth} ${unitHeight}`}
            overflow="visible"
            preserveAspectRatio="none"
            width={20}
            height={10}
          >
            <title>Menu</title>
            <motion.line
              x1="0"
              x2={unitWidth}
              y1="0"
              y2="0"
              variants={top}
              {...lineProps}
            />
            <motion.line
              x1="0"
              x2={unitWidth}
              y1="2"
              y2="2"
              variants={center}
              {...lineProps}
            />
            <motion.line
              x1="0"
              x2={unitWidth}
              y1="4"
              y2="4"
              variants={bottom}
              {...lineProps}
            />
          </motion.svg>
        </S.DrawerMenuButton>
      </S.Container>

      {showDrawer && (
        <S.DrawerContent
          initial={{y: 0, opacity: 0}}
          animate={{y: 0, opacity: 1}}
          exit={{y: 100, opacity: 0}}
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 30,
          }}
        >
          <S.DrawerLink selected={selected === '/'} href="/" aria-label="Home">
            Home
          </S.DrawerLink>

          {PAGES_PATH.map(page => {
            const {path, label} = page

            return (
              <S.DrawerLink
                selected={selected === path}
                href={path}
                key={path}
                aria-label={label}
              >
                {label}
              </S.DrawerLink>
            )
          })}

          <S.DrawerToggleWrapper>
            <ToggleThemeButton
              selectedTheme={THEME_LIGHT_SELECTED ? 'light' : 'dark'}
              toggleTheme={toggleTheme}
            />

            <S.KbarButton onClick={handleOpenKBar}>
              <Command size={16}/>
            </S.KbarButton>
          </S.DrawerToggleWrapper>
        </S.DrawerContent>
      )}
    </>
  )
}

export default memo(Header)
