import styled, {css} from 'styled-components'
import type ITheme from '@/styles/themes/interfaces'

import type {ITextProps} from './interfaces'

const textModifier = {
  xxsmall: (theme: ITheme) => css`
    font-size: ${theme.font.sizes.xxsmall};
    line-height: ${theme.font.lineHeight.xxsmall};
  `,
  xsmall: (theme: ITheme) => css`
    font-size: ${theme.font.sizes.xsmall};
    line-height: ${theme.font.lineHeight.xsmall};
  `,
  small: (theme: ITheme) => css`
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.font.lineHeight.small};
  `,
  medium: (theme: ITheme) => css`
    font-size: ${theme.font.sizes.normal};
    line-height: ${theme.font.lineHeight.normal};

    @media (max-width: ${theme.breakpoints.small}) {
      font-size: ${theme.font.sizes.small};
      line-height: ${theme.font.lineHeight.small};
    }
  `,
  large: (theme: ITheme) => css`
    font-size: ${theme.font.sizes.large};
    line-height: ${theme.font.lineHeight.large};

    @media (max-width: ${theme.breakpoints.small}) {
      font-size: 1.6rem;
    }
  `,

  weight: {
    normal: (theme: ITheme) => css`
      font-weight: ${theme.font.weights.normal};
    `,
    light: (theme: ITheme) => css`
      font-weight: ${theme.font.weights.light};
    `,
    semiBold: (theme: ITheme) => css`
      font-weight: ${theme.font.weights.semiBold};
    `,
    bold: (theme: ITheme) => css`
      font-weight: ${theme.font.weights.bold};
    `,
  },

  family: {
    primary: () => css``,
    secondary: () => css``,
  },

  italic: () => css`
    font-style: italic;
  `,
}

export const Text = styled.p<ITextProps>`
  ${({size, fontFamily, fontWeight, italic, color, theme}) => css`
    letter-spacing: 0.01rem;

    b {
      color: ${color || theme.colors.title};
      font-weight: ${theme.font.weights.semiBold};
    }

    em {
      color: ${color || theme.colors.title};
      font-style: normal;
      font-weight: ${theme.font.weights.medium};
    }

    a {
      cursor: pointer;
      color: ${color || theme.colors.title};

      transition: 0.2s ease-in-out;
      display: inline-block;
      position: relative;

      ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0.15rem;
        width: 100%;
        height: 0.01rem;
        opacity: 0.5;
        transition: 0.2s ease-in-out;
      }

      &:hover {
        &::after {
          background-color: ${theme.colors.highlightColor};
        }

        color: ${theme.colors.highlightColor};
      }
    }

    ${!!size && textModifier[size](theme)};
    ${!!fontWeight && textModifier.weight[fontWeight](theme)};
    ${!!fontFamily && textModifier.family[fontFamily]()};
    ${!!italic && textModifier.italic()};

    color: ${color || theme.colors.text};
  `}
`
