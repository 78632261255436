import {motion} from 'framer-motion'
import {darken} from 'polished'
import styled, {css} from 'styled-components'

import type {IButtonProps} from './interfaces'

export const Wrapper = styled(motion.button)<IButtonProps>`
  ${({theme}) => css`
    display: flex;
    width: max-content;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 0.8rem;
    box-sizing: border-box;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    text-align: center;
    background-color: ${theme.colors.button};
    padding: 0.5rem 2.4rem;
    gap: 0.8rem;

    span {
      font-size: 1.4rem;
      line-height: 3.2rem;
      color: ${theme.colors.buttonTextColor};
      font-family: ${theme.font.family};
    }

    @media (max-width: ${theme.breakpoints.small}) {
      padding: 0.5rem 1.6rem;
    }

    &:hover {
      background-color: ${darken(0.09, theme.colors.button)};
    }
  `}
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({theme}) => css`
    svg {
      color: ${theme.colors.buttonTextColor};
    }
  `}
`
