import type {Icon} from '@/components/SocialIcon/interfaces'
import {externalLinks} from '@/constants/externalLinks'
import {v4 as uuidv4} from 'uuid'

type SocialLink = {
  id: string
  icon: Icon
  name: string
  link: string
}

export const socialLinks: SocialLink[] = [
  {
    id: uuidv4(),
    icon: 'linkedIn',
    name: 'LinkedIn',
    link: externalLinks.linkedin,
  },
  {
    id: uuidv4(),
    icon: 'gitHub',
    name: 'GitHub',
    link: externalLinks.github,
  },
  {
    id: uuidv4(),
    icon: 'x',
    name: 'X',
    link: externalLinks.twitter,
  },
  {
    id: uuidv4(),
    icon: 'instagram',
    name: 'Instagram',
    link: externalLinks.instagram,
  },
  {
    id: uuidv4(),
    icon: 'medium',
    name: 'Medium',
    link: externalLinks.medium,
  },
  {
    id: uuidv4(),
    icon: 'productHunt',
    name: 'Product Hunt',
    link: externalLinks.product_hunt,
  },
]
