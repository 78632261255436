import {Button} from '@radix-ui/themes'
import {Card, Heading, SocialIcon, Text} from '@/components'
import {externalLinks} from '@/constants/externalLinks'
import {Mail} from 'lucide-react'
import {useRouter} from 'next/router'
import {memo} from 'react'

import type {IThoughtsBoxProps} from './interfaces'
import * as S from './styles'

const DEFAULT_TITLE = 'Thoughts about this article?'
const DEFAULT_DESCRIPTION =
  "I'm all ears for feedback! Typos? Did something specific get your attention? Anything else? I'd love to hear! Drop me a note somewhere."

const ThoughtsBox = ({
                       title = DEFAULT_TITLE,
                       description = DEFAULT_DESCRIPTION,
                     }: IThoughtsBoxProps) => {
  const {push} = useRouter()

  return (
    <S.CardStyled>
      <Card>
        <S.Container>
          <Heading level={3}>{title}</Heading>

          <Text>{description}</Text>

          <S.ButtonsWrapper>
            <Button
              radius="full"
              variant="surface"
              color="indigo"
              onClick={() => push('/contact')}
            >
              <Mail size={14}/> Drop an email
            </Button>
            <Button
              radius="full"
              variant="surface"
              onClick={() => push(externalLinks.twitter)}
            >
              <SocialIcon icon="x" width={14}/> Send an DM
            </Button>
          </S.ButtonsWrapper>
        </S.Container>
      </Card>
    </S.CardStyled>
  )
}

export default memo(ThoughtsBox)
