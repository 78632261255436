import {Text} from '@/components'
import {ChevronRight} from 'lucide-react'
import Link from 'next/link'
import {memo} from 'react'

import type {IBreadCrumbProps} from './interfaces'
import * as S from './styles'

const Breadcrumbs = ({crumbs}: IBreadCrumbProps) => {
  if (crumbs.length <= 1) {
    return null
  }

  return (
    <S.Container>
      {crumbs.map(({name, path}, key) =>
        key + 1 === crumbs.length ? (
          <Text size="xsmall" as="small" key={key}>
            {name.toLocaleLowerCase()}
          </Text>
        ) : (
          <S.LinkWrapper key={key}>
            <Link href={path}>{name.toLocaleLowerCase()}</Link>

            <ChevronRight size={12}/>
          </S.LinkWrapper>
        ),
      )}
    </S.Container>
  )
}

export default memo(Breadcrumbs)
