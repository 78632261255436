import styled, {css} from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`

export const LinkWrapper = styled.div`
  ${({theme}) => css`
    display: flex;
    align-items: center;
    gap: 0.8rem;

    a {
      font-size: 1.4rem;
      color: ${theme.colors.highlightColor};
    }
  `}
`
