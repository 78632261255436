import * as amplitude from '@amplitude/analytics-browser'
import {usePathname} from 'next/navigation'

export default function useAmplitude() {
  const pathname = usePathname()

  const trackEvent = (
    eventName: string,
    eventProperties?: Record<string, any>,
  ) => {
    const defaultEventProperties = {
      metadata: {
        url: window.location.href,
        path: pathname,
        title: document.title,
        screen: `${window.screen.width}x${window.screen.height}`,
        language: navigator.language,
        platform: navigator.platform,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        user_agent: navigator.userAgent,
      },
      device_info: {
        screen_resolution: `${window.screen.width}x${window.screen.height}`,
        screen_viewport: `${window.innerWidth}x${window.innerHeight}`,
        screen_viewport_resolution: `${window.innerWidth}x${window.innerHeight}`,
        screen_viewport_viewable_area: `${window.innerWidth}x${window.innerHeight}`,
      },

      ...eventProperties,
    }

    amplitude.track(eventName, defaultEventProperties)
  }

  return {
    trackEvent,
  }
}
