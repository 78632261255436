import type {IStartupModel} from '@/models'
import {v4 as uuidv4} from 'uuid'

export const startups: IStartupModel[] = [
  {
    id: uuidv4(),
    image: '/assets/images/startups/woney.png',
    description:
      "Woney is a Brazilian startup with the aim of democratizing financial well-being in the country. We are creating the most comprehensive platform dedicated to users' financial health, with our primary focus on education. We believe that learning about finances can become easy and enjoyable, and that access should be free for everyone. We offer a variety of products and tools to empower our users to achieve their goals and dreams, all in one place.",
    link: 'https://woney.com.br',
    name: 'Woney',
    role: 'Founder & CEO',
  },
  {
    id: uuidv4(),
    image: '/assets/images/startups/vife-solutions.jpg',
    description:
      'VIFE Solutions is a Brazilian innovative software development studio committed to sustainability, that mixes beauty, strategic insight, and cultural essence to birth novel concepts into the global stage. Our diverse team, deeply entrenched in a multitude of disciplines, remains steadfast in our pursuit of crafting captivating interface designs, elevating user experiences, and spearheading advancements in web and mobile development.',
    link: 'https://vifesolutions.com',
    name: 'VIFE Solutions',
    role: 'CEO',
  },
]
