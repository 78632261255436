import React, {memo, useState} from 'react'

import type {IToggleProps} from './interfaces'
import * as S from './styles'

function Toggle({handleSwitch, value}: IToggleProps) {
  const [isOn, setIsOn] = useState(value || false)

  const toggleSwitch = () => {
    setIsOn(!isOn)
    handleSwitch(!isOn)
  }

  return (
    <S.Container>
      <S.Switch isOn={isOn} onClick={toggleSwitch}>
        <S.Handle
          className="handle"
          layout
          transition={{
            type: 'spring',
            stiffness: 700,
            damping: 30,
          }}
        />
      </S.Switch>
    </S.Container>
  )
}

export default memo(Toggle)
