import {Tooltip} from '@radix-ui/themes'
import {Flag, SocialIcon, Text} from '@/components'
import {socialLinks} from '@/data'
import {memo} from 'react'

import type {IFooterProps} from './interfaces'
import * as S from './styles'

function Footer({showSocialButtons = true}: IFooterProps) {
  return (
    <S.Container>
      {showSocialButtons && (
        <S.SocialWrapper>
          {socialLinks.map(item => (
            <Tooltip key={item.id} content={`Open ${item.name}`}>
              <S.SocialItem
                href={item.link}
                target="_blank"
                aria-label={item.icon}
                whileTap={{
                  scale: 0.9,
                }}
              >
                <SocialIcon icon={item.icon}/>
              </S.SocialItem>
            </Tooltip>
          ))}
        </S.SocialWrapper>
      )}

      <Text size="small">©{new Date().getFullYear()} Vinnicius Gomes</Text>

      <S.CreatedIn>
        <Text size="xsmall">Created in </Text>
        <Flag.BR width={16}/>
      </S.CreatedIn>
    </S.Container>
  )
}

export default memo(Footer)
