import {default as BR} from './br'
import {default as PT} from './pt'
import {default as Spain} from './spain'

const Flag = {
  BR,
  PT,
  Spain,
}

export default Flag
