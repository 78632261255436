import darkTheme from './dark'
import lightTheme from './light'

const themes = {
  LIGHT_THEME: lightTheme,
  DARK_THEME: darkTheme,
}

export type ThemesKeys = keyof typeof themes

export const THEMES_KEYS: { [key in ThemesKeys]: ThemesKeys } = {
  LIGHT_THEME: 'LIGHT_THEME',
  DARK_THEME: 'DARK_THEME',
}

export default themes
