interface Recommendation {
  name: string
  company: string
  companyLink: string
  linkedInProfile: string
  position: string
  recommendation: string
  picture?: string
}

export const recommendations: Recommendation[] = [
  {
    name: 'Pablo Augusto',
    picture: '/assets/images/recommendations/pablo_augusto.jpeg',
    company: 'Zappts & Coren',
    companyLink: 'https://coren.tech/',
    linkedInProfile: 'https://www.linkedin.com/in/pabloaugustotech',
    position: 'Founder & CEO',
    recommendation:
      'I worked with Vinnicius in two companies that I founded (Zappts & Coren), and I always appreciated him as both a professional and a great person. Vinnicius is extremely committed to everything he does, driven by the highest ethical standards, excels in teamwork, and understands how to meet the needs of customers, partners, and stakeholders. He possesses great technical knowledge and skills. For these reasons and many more, I highly recommend him!',
  },
  {
    name: 'Rodrigo Bornholdt',
    company: 'Zappts & Coren',
    picture: '/assets/images/recommendations/rodrigo_bornholdt.jpeg',
    companyLink: 'https://coren.tech/',
    linkedInProfile: 'https://www.linkedin.com/in/rodrigo-bornholdt',
    position: 'Founder & CTO',
    recommendation:
      'I highly recommend Vinnicius for any senior frontend development role. With years of experience, Vinnicius has demonstrated exceptional technical skills in developing and delivering complex web applications. His expertise in HTML, Javascript, ReactJS, CSS, microfrontends, and BFFs has been invaluable in driving project success and delivering high-quality results. Vinnicius is always willing to go the extra mile to ensure project success and is open to constructive criticism to continuously improve. I have no doubt that Vinnicius would excel in a technical leadership role or as a team manager. His combination of technical expertise and strong interpersonal skills would be an asset to any organization. I wholeheartedly recommend him for any senior frontend development role and am confident he will make a positive impact.',
  },
  {
    name: 'Livio França',
    picture: '/assets/images/recommendations/livio_franca.jpeg',
    company: 'Coren',
    companyLink: 'https://coren.tech/',
    linkedInProfile: 'https://www.linkedin.com/in/liviofrol',
    position: 'Co-Founder',
    recommendation:
      "As Vinni's leader, I was fortunate to have his dedication, creativity, and intelligence on our team. His entrepreneurial mindset consistently brought innovative solutions and helped us think beyond limitations. Moreover, his collaboration and ability to work as a team were invaluable to our success at Receba. I confidently recommend Vinni to any company or project seeking someone dedicated, creative, and intelligent.",
  },
  {
    name: 'Rodrigo Ferreira',
    picture: '/assets/images/recommendations/rodrigo_goncalves.jpeg',
    company: 'UOL',
    companyLink: 'https://uol.com.br',
    linkedInProfile: 'https://www.linkedin.com/in/rodrigoferreiradigao',
    position: 'General Manager of Software Engineering',
    recommendation:
      'Although Vinnicius was with us for a short time, he consistently demonstrated competence and dedication, actively participating in the frontend development of two of our React projects. Exhibiting a strong profile in technical leadership, I highly recommend him.',
  },
  {
    name: 'Carla Evaristo',
    picture: '/assets/images/recommendations/carla_evaristo.jpeg',
    company: 'Zappts',
    companyLink: 'https://zappts.com.br/',
    linkedInProfile: 'https://www.linkedin.com/in/carlaevaristo',
    position: 'Head of Quality & Process',
    recommendation:
      'I had the great opportunity to work with Vinni, and he never ceased to amaze me! He consistently strived to apply best practices, collaborated closely with the quality team, and was always focused on delivering the best results. Even when faced with challenging deadlines, he continued to excel. On top of everything, Vinni maintained a positive relationship with the team, collaborating with everyone.',
  },
  {
    name: 'Guaraci Nakamura',
    picture: '/assets/images/recommendations/guaraci_nakamura.jpeg',
    company: 'Coren',
    companyLink: 'https://coren.tech/',
    linkedInProfile: 'https://www.linkedin.com/in/guaracinakamura',
    position: 'Squad Leader',
    recommendation:
      "An excellent professional! One of the things that caught my attention the most was his passion for his work. Always attentive to the latest developments, studious, and leading discussion forums to share his knowledge and news. When he joined the Coren team, he made a significant impact by restructuring the platform's architecture, raising the bar and the level of deliverables, often exceeding expectations with his high-quality and agile deliveries. A great mentor and team manager.",
  },
  {
    name: 'Tiago Miller',
    picture: '/assets/images/recommendations/tiago_miller.jpeg',
    company: 'Embraer',
    companyLink: 'https://embraer.com/',
    linkedInProfile: 'https://www.linkedin.com/in/tiagomiller',
    position: 'Product Owner',
    recommendation:
      'Vinnicius is an exceptional professional. Technically excellent in all aspects, from planning and implementation to operation. He possesses strong leadership skills. When we worked together, he joined with the mentioned profile, still developing his leadership abilities, which evolved rapidly and enabled him to overcome various challenges.',
  },
  {
    name: 'Felipe Fernandes',
    picture: '/assets/images/recommendations/felipe_fernandes.jpeg',
    company: 'UOL',
    companyLink: 'https://uol.com.br',
    linkedInProfile:
      'https://www.linkedin.com/in/felipe-fernandes-vieira-6587b0136/',
    position: 'Product Owner',
    recommendation:
      'I had the opportunity to work with Vinnicius on a major UOL project. Both as a professional and as a person, he exemplifies dedication and respect for his work.',
  },
  {
    name: 'Daniel Lima',
    picture: '/assets/images/recommendations/daniel_lima.jpeg',
    company: 'Grupo NC',
    companyLink: 'https://www.gruponc.net.br/',
    linkedInProfile: 'https://www.linkedin.com/in/danieldeveloper/',
    position: 'Senior Frontend Engineer',
    recommendation:
      'Vinni is the best Frontend Developer I have ever had the pleasure of working with. Over time, he has significantly advanced in his career, mastering various languages and standing out in the market due to his empathy, highly effective techniques, and continuous learning. We have worked together on several projects, and his ability to deliver exceptional results has always been outstanding. I highly recommend Vinnicius, whether for a Developer role or to take on leadership positions. His combination of technical skills and human qualities makes him a valuable addition to any team.',
  },
  {
    name: 'Victor C. Marinho',
    picture: '/assets/images/recommendations/victor_marinho.jpeg',
    company: 'ReclameAqui',
    companyLink: 'https://www.reclameaqui.com.br/',
    linkedInProfile: 'https://www.linkedin.com/in/victorcmarinho/',
    position: 'Senior Frontend Developer',
    recommendation:
      'Vinnicius is a fantastic programmer with strong logical thinking and quick problem-solving skills. He possesses extensive knowledge of React. Vinnicius is highly qualified, dedicated, and studious. He collaborates effectively within a team and has consistently made efforts to help the team with various challenges.',
  },
  {
    name: 'Mykhailo Hudzevatyi',
    picture: '/assets/images/recommendations/mykhailo_hudzevatyi.jpeg',
    company: 'Think Company',
    companyLink: 'https://www.thinkcompany.com/',
    linkedInProfile: 'https://www.linkedin.com/in/michaelgudzevskyi/',
    position: 'Senior Frontend Developer',
    recommendation:
      'I would recommend Vinnicius for many reasons, but his one attribute that stands out above all is his passion. He has been excited about coding since undergrad and that passion has not faded. He gives it his all, finishes projects in a timely manner, polishes his code day and night, and always makes himself available. He is a valuable team player.',
  },
  {
    name: 'Maik Vinícius Guimarães',
    picture: '/assets/images/recommendations/maik_vinicius.jpeg',
    company: 'Flapper',
    companyLink: 'https://flyflapper.com/',
    linkedInProfile: 'https://www.linkedin.com/in/maikvinicius',
    position: 'Senior Mobile Developer',
    recommendation:
      'No words can truly describe Vinnicius Gomes. A specialist in what he does, his vast experience and extensive background significantly contribute to the growth of any project he is involved in. A charming person with a big heart, Vinnicius is always willing to help and grow alongside the team. I highly recommend his work and his exceptional skills.',
  },
  {
    name: 'Aline Lima de Paula',
    picture: '/assets/images/recommendations/aline_lima.jpeg',
    company: 'CI&T',
    companyLink: 'https://www.linkedin.com/company/ciandt/',
    linkedInProfile: 'https://www.linkedin.com/in/aline-lima-de-paula/',
    position: 'Software QA Engineer',
    recommendation:
      'Vinnicius is an excellent professional with extensive knowledge in React, consistently dedicated and eager to learn. We worked on projects for leading companies, and in all challenges, Vinni managed to deliver with exceptional quality. Without a doubt, he is a great professional.',
  },
  {
    name: 'Jonatas De Oliveira Ferreira',
    picture: '/assets/images/recommendations/jonatas_ferreira.jpeg',
    company: 'Coren',
    companyLink: 'https://coren.tech/',
    linkedInProfile: 'https://www.linkedin.com/in/jonatas-oliveira-ferreira',
    position: 'Frontend Engineer',
    recommendation:
      'Vinni served as my technical mentor for a considerable time, and through him, I learned a great deal about becoming an outstanding Frontend developer. Thanks to his guidance, I gained exposure to various technologies, project structures, and acquired knowledge in just one year that would have taken me 4-5 years to learn on my own. The insights I gained were incredible and unforgettable, all thanks to him. As a result of his mentorship, I was able to advance from a Junior developer to a Full developer.',
  },
  {
    name: 'Bruno Toral',
    picture: '/assets/images/recommendations/bruno_toral.jpeg',
    company: 'Líber',
    companyLink: 'https://libercapital.com.br/',
    linkedInProfile: 'https://www.linkedin.com/in/bruno-toral-6b55ba128/',
    position: 'Software Engineer',
    recommendation:
      'An exemplary professional! His passion for what he does is evident, always seeking new developments and enhancing his knowledge. He consistently takes the lead on initiatives, shares valuable insights, and stands out as a true technical reference.',
  },
  {
    name: 'João Paulo',
    picture: '/assets/images/recommendations/joao_paulo.jpeg',
    company: 'Coren',
    companyLink: 'https://coren.tech/',
    linkedInProfile: 'https://www.linkedin.com/in/joaopmmorais',
    position: 'Frontend Developer',
    recommendation:
      "Vinnicius is one of the most competent professionals I have had the opportunity to work with. Don't be fooled by his young age; he possesses the professionalism that any company would wish to have on its team, incredibly advanced technical knowledge in software development, and he is a great leader. During my time with Vinnicius at Coren, he was my supervisor and technical mentor, from whom I learned a great deal. I witnessed how much he values the development of the entire team. I highly recommend Vinnicius.",
  },
]
