import {memo} from 'react'

import type {IContentProps} from './interfaces'
import * as S from './styles'

function Content({hasMarginTop = true, children}: IContentProps) {
  return <S.Container hasMarginTop={hasMarginTop}>{children}</S.Container>
}

export default memo(Content)
