import {DropdownMenu, Kbd as RadixUIKbd} from '@radix-ui/themes'
import {motion} from 'framer-motion'
import Link from 'next/link'
import styled, {css} from 'styled-components'

type INavProps = {
  selected: boolean
}

type IContainerProps = {
  scrolling: boolean
}

export const Container = styled.header<IContainerProps>`
  ${({theme, scrolling}) => css`
    width: 100%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
    padding: 2rem 0;

    transition: background-color 0.3s ease;

    ${
  scrolling &&
  css`
      backdrop-filter: saturate(180%) blur(20px);
      background-color: ${theme.colors.headerColor};
      border-bottom: 0.1rem solid ${theme.colors.headerBottomColor};
    `
}

    @media (max-width: ${theme.breakpoints.xxlarge}) {
      padding: 2.4rem 6.4rem;
    }

    @media (max-width: ${theme.breakpoints.medium}) {
      padding: 2.4rem 3.2rem;
    }

    @media (max-width: ${theme.breakpoints.small}) {
      padding: 1.6rem 2.4rem;
      border: none;
    }

    @media (max-width: ${theme.breakpoints.xlarge}) {
      background-color: ${theme.colors.background};
    }
  `}
`

export const Content = styled.div`
  max-width: 132.5rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Navigation = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: -1.6rem;
`

export const NavigationItem = styled(motion.div)<INavProps>`
  ${({theme, selected}) => css`
    position: relative;
    padding: 0 2rem;
    cursor: pointer;

    span {
      font-weight: ${theme.font.weights.medium};
      text-transform: uppercase;
      font-size: 1.1rem;
      letter-spacing: 0.12rem;
      line-height: 2.8rem;
      color: ${selected ? theme.colors.title : theme.colors.text};
      transition: color 0.2s ease-in-out;

      ::after {
        content: "";
        position: absolute;
        margin: 0 auto;
        top: 3rem;
        left: 0;
        right: 0;
        height: 0.15rem;
        width: 2.4rem;
        background: ${theme.colors.title};
        opacity: ${selected ? 1 : 0};
        transition: opacity 0.2s ease-in-out;
      }
    }

    &:hover {
      span,
      svg {
        color: ${theme.colors.title};
      }
    }

    @media (max-width: ${theme.breakpoints.xlarge}) {
      display: none;
    }
  `}
`

export const NavigationDropdownContent = styled(DropdownMenu.Content)`
  ${({theme}) => css`
    min-width: 25.6rem;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: ${theme.colors.headerColor};
    border-bottom: 0.1rem solid ${theme.colors.headerBottomColor};
    border-radius: 0.8rem;
    border: 0.1rem solid ${theme.colors.borderColor};
    padding: 0.8rem 0;

    .rt-BaseMenuViewport {
      display: grid;
      gap: 0.8rem;
    }

    .rt-BaseMenuItem {
      cursor: pointer;
    }

    .rt-BaseMenuItem[data-highlighted] {
      background-color: ${theme.colors.headerHoverColor} !important;
    }

    a {
      height: 3.2rem !important;
      width: 100%;
      cursor: pointer;
    }
  `}
`

export const LogoWrapper = styled.div`
  ${({theme}) => css`
    position: sticky;
    left: 4.8rem;

    @media (max-width: ${theme.breakpoints.xlarge}) {
      position: initial;
    }
  `}
`

export const Logo = styled(NavigationItem)<INavProps>`
  ${({theme, selected}) => css`
    padding: 0;
    margin-right: 2rem;

    span {
      color: ${selected ? theme.colors.title : theme.colors.text};
      font-weight: 900;
      font-size: 1.4rem;
      ::after {
        display: none;
      }
    }

    @media (max-width: ${theme.breakpoints.xlarge}) {
      display: block;
    }
  `}
`

export const NavigationItemHover = styled(motion.div)`
  ${({theme}) => css`
    position: absolute;
    top: -0.8rem;
    left: 0;
    bottom: -0.8rem;
    right: 0;
    background: ${theme.colors.headerHoverColor};

    border-radius: 0.8rem;
    z-index: -1;
  `}
`

export const ToggleWrapper = styled.div`
  ${({theme}) => css`
    display: flex;
    align-items: center;
    gap: 1.6rem;

    position: sticky;
    right: 4.8rem;

    span {
      color: ${theme.colors.text};
      font-weight: ${theme.font.weights.medium};
    }

    @media (max-width: ${theme.breakpoints.xlarge}) {
      display: none;

      position: initial;
    }
  `}
`

export const KbarButton = styled.button`
  ${({theme}) => css`
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    margin-top: -0.2rem;

    svg {
      color: ${theme.colors.title};
    }
  `}
`

export const Kbd = styled(RadixUIKbd)`
  background: rgba(255, 255, 255, 0.1);
  color: #8f9ba8;
  padding: 0.6rem 0.8rem;
  display: flex;
  align-items: center;
  padding-top: 0.7rem;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 0.4rem;
  font-size: 1.2rem;
  cursor: pointer;
`

export const DrawerMenuButton = styled.div`
  ${({theme}) => css`
    display: none;

    @media (max-width: ${theme.breakpoints.xlarge}) {
      display: block;
    }
  `}
`

export const DrawerContent = styled(motion.div)`
  ${({theme}) => css`
    position: fixed;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.colors.background};
    border-bottom: 0.1rem solid ${theme.colors.headerBottomColor};
    top: 5.4rem;
    gap: 1.6rem;
    padding: 0 0 3.2rem;

    a {
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  `}
`

export const DrawerLink = styled(Link)<INavProps>`
  ${({theme, selected}) => css`
    color: ${selected ? theme.colors.title : theme.colors.text};
    font-size: 1.2rem;
  `}
`

export const DrawerToggleWrapper = styled(ToggleWrapper)`
  ${({theme}) => css`
    margin-top: 3.2rem;

    @media (max-width: ${theme.breakpoints.xlarge}) {
      display: flex;
    }
  `}
`
