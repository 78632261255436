import type {IProjectModel} from '@/models'
import {v4 as uuidv4} from 'uuid'

export const projects: IProjectModel[] = [
  {
    id: uuidv4(),
    slug: 'ton',
    name: 'Ton',
    description:
      'Ton is a payment solution by Stone aimed at micro and small entrepreneurs. With a focus on accessibility and convenience, Ton offers card machines and a digital platform that enable these businesses to receive payments quickly and securely, whether through credit cards, debit cards, or Pix. Additionally, Ton provides a range of financial features, such as receivables anticipation and sales management, all in an intuitive and hassle-free environment.',
    image: {
      url: '/assets/images/projects/ton-app.png',
    },
    madeAt: 'Stone & Co.',
    madeAtUrl: 'https://stone.com.br',
    link: 'https://ton.com.br/',
    featuredProject: true,
    year: 2024,
    current: true,
    stack: ['React Native', 'Kotlin Multiplatform', 'iOS', 'Android'],
  },
  {
    id: uuidv4(),
    slug: 'smartleiloes',
    name: 'Smart Leilões',
    description:
      'Smart Leilões was founded by Pedro, Pierre, and Renan to transform education in real estate auctions. After three years of investing, they aimed to share effective strategies for achieving high returns. I led the redesign of the Smart Leilões platform, enhancing it for students to easily find auction properties across Brazil. The updated platform allows users to connect with other auctioneers, perform simulations, and monitor properties, significantly improving their learning experience and confidence.',
    image: {
      url: '/assets/images/projects/smartleiloes.png',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: 'https://teamsmartleiloes.com.br',
    featuredProject: false,
    year: 2024,
    stack: ['Angular', 'TypeScript', 'MUI'],
  },
  {
    id: uuidv4(),
    slug: 'blombo',
    name: 'Blombô',
    description:
      "Blombô.com is an innovative online platform designed to revolutionize the art auction experience by seamlessly connecting collectors, enthusiasts, and artists. For this project, I led a comprehensive discovery process to develop an efficient and user-friendly online art auction system. Over a two-month period, we focused on two key phases: first, the discovery and product definition, where we outlined core functionalities, user experience flows, and essential features to meet the market's needs; and second, the technical definition, where we selected the appropriate technologies and architectural frameworks to ensure the platform's scalability, performance, and security. The result is a platform that caters to both seasoned collectors and newcomers, offering a curated selection of artworks in a secure, intuitive environment for discovering, buying, and selling unique pieces.",
    image: {
      url: '/assets/images/projects/blombo.png',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: 'https://blombo.com',
    featuredProject: false,
    year: 2024,
    stack: ['Go', 'Node.js', 'React', 'AWS', 'gRPC'],
  },
  {
    id: uuidv4(),
    slug: 'bergamia',
    name: 'Bergamía',
    description:
      "Natural cosmetics created and developed by Brazilian biotechnology. This is an invitation for nature's technology to take a new place in your skin and life; sharing experiences, reflections, and gratitude. Discover our line of natural and vegan biocosmetics with high performance on your skin and less impact on the planet. Your skin is our habitat.",
    image: {
      url: '/assets/images/projects/bergamia.png',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: 'https://bergamia.com.br',
    featuredProject: false,
    year: 2024,
    stack: ['Shopify'],
  },
  {
    id: uuidv4(),
    slug: 'conserva',
    name: 'Conserva',
    description:
      'Conserva create strategies, projects and products for regenerative impact. They use impact analysis, cultural insights, and a powerful network to act in the intersection of cultural, social and natural systems. They clients are design-oriented brands and organizations willing to question dominant sustainability myths and see a new vision of value.',
    image: {
      url: '/assets/images/projects/conserva.png',
    },
    madeAt: 'VG Studio',
    madeAtUrl: 'https://vinniciusgomes.dev',
    link: 'https://conserva.org',
    featuredProject: false,
    year: 2024,
    stack: ['React', 'Next.js', 'GraphQL'],
  },
  {
    id: uuidv4(),
    slug: '7the',
    name: '7th Experience',
    description:
      'The 7TH Experience was born with the purpose of revolutionizing the corporate events experience, taking knowledge and networking to the next level. With over 15 years of accumulated experience in strategic networking, we connect people and ideas to generate business and value in Brazil and around the world. Going far beyond content, our vision is that each event is a unique experience, adding value not only to the professional moment or career but also providing engagement and support in decision-making, inspiring ideas and innovative solutions in the face of uncertain scenarios.',
    image: {
      url: '/assets/images/projects/7the-lp.png',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: 'https://7the.com.br',
    featuredProject: false,
    year: 2023,
    stack: ['React', 'Next.js', 'GraphQL'],
  },
  {
    id: uuidv4(),
    slug: 'id-day',
    name: 'IT Day',
    description:
      "The IT Day is an event that is heading into its 6th edition, bringing together Innovation and Technology Leaders for a day filled with valuable content, networking, fun, and a bit of swag. This edition will take place on November 9th at Inteli - Institute of Technology and Leadership. All of the event's programming and content are exclusively tailored for industry leaders, addressing the most relevant topics of the year in the sector, creating an opinion-shaping environment.",
    image: {
      url: '/assets/images/projects/it-day.png',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: 'https://www.itday.com.br',
    featuredProject: false,
    year: 2023,
    stack: ['React', 'Next.js', 'GraphQL'],
  },
  {
    id: uuidv4(),
    slug: 'flower-bar',
    name: 'Flower Bar',
    description:
      "Lucila Hobberman created FLOWERBAR in 2017 with the purpose of allowing customers to choose their flowers and obtain a personalized, unique, and free bouquet. Over time, this purpose became the brand's DNA and the essence of all the bouquets we create. In 2019, Fernando Frisoni joined as a partner, opening doors to events and networking. It is fulfilling to work on bringing joy and blossoming beauty, to be messengers, writing love letters and thank-you cards. This energy is passed on to each bouquet we create and every satisfied customer we leave behind.",
    image: {
      url: '/assets/images/projects/flowerbar.png',
    },
    madeAt: 'Camila Bossolan',
    madeAtUrl: 'https://camilabossolan.com.br',
    link: 'https://flowerbar.com.br',
    featuredProject: false,
    year: 2023,
    stack: ['Shopify'],
  },
  {
    id: uuidv4(),
    slug: 'livefarm',
    name: 'LiveFarm',
    description:
      "Agritech made by farmers, for farmers. Every tradition is an innovation that succeeded. That's why we drive the future traditions of agribusiness, creating and providing vital innovations that ensure productivity and sustainability for the farms of Brazil.",
    image: {
      url: '/assets/images/projects/livefarm.png',
    },
    madeAt: 'Hydr',
    madeAtUrl: 'https://hydr.com.br',
    link: 'https://livefarm.com.br',
    featuredProject: false,
    year: 2023,
    stack: ['React', 'Next.js', 'GraphQL'],
  },
  {
    id: uuidv4(),
    slug: 'ssv',
    name: 'SSV',
    description:
      'SSV is a venture capital company that focuses on investing in Latin America and other regions of the global south. We are committed to driving growth and economic development in these areas by supporting visionary and innovative entrepreneurs who are seeking to transform their businesses into success stories.',
    image: {
      url: '/assets/images/projects/ssv.jpeg',
    },
    madeAt: 'Camila Bossolan',
    madeAtUrl: 'https://camilabossolan.com.br',
    link: 'https://ssv.me',
    featuredProject: false,
    year: 2023,
    stack: ['React'],
  },
  {
    id: uuidv4(),
    slug: 'Líber',
    name: 'Líber Capital',
    current: false,
    description:
      "Líber offers convenient Multifunding, seamlessly integrating a wide network of financing partners, including banks and FIDCs. With own cash reserve, it unlocks the potential of self-owned resources, allowing industries and large companies to finance suppliers. Through the online auction model, Líber provides access to the best rates as multiple financiers compete. Its exclusive reversed receivables solution offers greater control and flexibility. Líber's comprehensive financing options elevate businesses to new heights.",
    image: {
      url: '/assets/images/projects/liber.jpg',
    },
    madeAt: 'Líber',
    madeAtUrl: 'https://libercapital.com.br',
    link: '/projects/liber',
    featuredProject: true,
    year: 2023,
    stack: ['React', 'MUI', 'Ruby', 'Go'],
  },
  {
    id: uuidv4(),
    slug: 'woney',
    name: 'Woney',
    current: false,
    description:
      "Woney is a startup that aims to revolutionize the way people utilize money. Our mission is to develop the world's best financial education program and make it universally accessible. Finance courses are typically complex and costly; however, we believe that learning about finance can be made easy and enjoyable, and that access to this education should be free for everyone.",
    image: {
      url: '/assets/images/projects/woney/woney-1.png',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: '/projects/woney',
    featuredProject: true,
    year: 2023,
    stack: ['React Native', 'Firebase', 'iOS', 'Android'],
  },
  {
    id: uuidv4(),
    slug: 'receba',
    name: 'Receba',
    description:
      "Receba is a points-based platform that focuses on engaging sellers. Our solution enables partner companies to create appealing campaigns for sellers, thereby increasing the volume and reach of their sales. With our data intelligence capabilities, entrepreneurs and partner companies can access consistent metrics to make important decisions regarding generating more sales and optimizing investments. Through our platform, sellers have access to sales reward programs in one centralized location, and they can redeem the points they've earned in various ways, including as cash.",
    image: {
      url: '/assets/images/projects/receba.png',
    },
    madeAt: 'Coren',
    madeAtUrl: 'https://coren.digital',
    link: '/projects/receba',
    featuredProject: true,
    year: 2023,
    stack: ['React', 'Styled Components', 'PWA'],
  },
  {
    id: uuidv4(),
    slug: 'coren',
    name: 'BTG Banking Loyalty',
    description:
      'To create a new retail bank aimed at serving high-net-worth individuals with an exclusive experience, BTG Pactual, the largest investment bank in Latin America, launched BTG Banking with an innovative loyalty program, unlike anything in the market. In the following article, you will read about the development of the BTG Banking loyalty program through the Coren platform.',
    image: {
      url: '/assets/images/projects/btg-pactual.png',
    },
    madeAt: 'Coren',
    madeAtUrl: 'https://coren.digital',
    link: '/projects/btg',
    featuredProject: true,
    year: 2022,
    stack: ['React', 'Node.js', 'Amazon Web Services'],
  },
  {
    id: uuidv4(),
    slug: 'coren',
    name: 'Coren',
    description:
      'Coren was created to address the complexity of personalizing customer experiences by assisting companies in organizing all their customer data and empowering them to use that data to create tailored experiences for their customers. We tackle this complexity by leveraging customer data through our user interface, making it easier to design loyalty, incentives, and rewards programs.',
    image: {
      url: '/assets/images/projects/iclubs.jpg',
    },
    madeAt: 'Coren',
    madeAtUrl: 'https://coren.digital',
    link: 'https://coren.digital',
    featuredProject: false,
    year: 2022,
    stack: ['React'],
  },
  {
    id: uuidv4(),
    slug: 'uol-loja-de-jogos',
    name: 'UOL Loja de Jogos',
    description:
      'UOL Games Store is a platform designed for purchasing credits and resources for electronic games. The project focused on revitalizing the product by completely overhauling the technical aspects and expanding the catalog through a partnership with Hype, another Brazilian company focused on the same segment. After an extensive development process, the store was launched just in time for Black Friday 2021. It has already seen profits and a high demand, demonstrating its potential for continued evolution in the years to come.',
    image: {
      url: '/assets/images/projects/lojadejogos.jpeg',
    },
    madeAt: 'UOL',
    madeAtUrl: 'https://uol.com.br',
    link: 'https://lojadejogos.uol.com.br',
    featuredProject: false,
    year: 2021,
    stack: ['React', 'BFF'],
  },
  {
    id: uuidv4(),
    slug: 'uol-play',
    name: 'UOL Play',
    description:
      "UOL Play is currently one of Globoplay's main competitors. The content can be accessed online through the UOL Play website or the streaming service's application. Among the packages offered, viewers can watch programs from various broadcasters and video producers. One of the key features of the streaming service is the ability to connect up to four screens simultaneously. This means that UOL Play allows you to register up to four profiles on the same plan, even with the cheapest package.",
    image: {
      url: '/assets/images/projects/uol-play.jpeg',
    },
    madeAt: 'UOL',
    madeAtUrl: 'https://uol.com.br',
    link: 'https://play.uol.com.br',
    featuredProject: true,
    year: 2021,
    stack: ['React', 'Styled Components', 'Streaming'],
  },
  {
    id: uuidv4(),
    slug: 'uol-esporte-club',
    name: 'UOL Esporte Clube',
    description:
      'UOL Esporte Clube is a subscription-based service that provides access to the best sports content from Brazil and around the world, both live and on-demand. The platform features a range of live and on-demand sports programs, allowing viewers to stay up-to-date on the latest events and matches. Users can check the schedule for various sports programs, including TNT Sports Stadium, Nordeste FC, NBA, and more.',
    image: {
      url: '/assets/images/projects/esporteclube.png',
    },
    madeAt: 'UOL',
    madeAtUrl: 'https://www.uol.com.br',
    link: 'https://esporteclube.uol.com.br',
    featuredProject: false,
    year: 2021,
    stack: ['React', 'BFF'],
  },
  {
    id: uuidv4(),
    slug: 'getnet-checkout',
    name: 'Get Checkout',
    description:
      "Getnet is a cutting-edge global payment platform that assists businesses in securely accepting payments while providing customers with the best possible checkout experience. With Getnet's e-commerce solution, you can access a comprehensive e-commerce solution on a single platform. Additionally, you can accept major card brands and enjoy secure transactions. The solution features acquiring services, payment gateway, checkout, among others, providing greater efficiency, security, and easier management of your online store.",
    image: {
      url: '/assets/images/projects/getnet.jpeg',
    },
    madeAt: 'Zappts',
    madeAtUrl: 'https://zappts.com.br',
    link: '/projects/getnet',
    featuredProject: true,
    year: 2021,
    stack: ['React', 'BFF', 'NestJS'],
  },
  {
    id: uuidv4(),
    slug: 'ultragaz-empresarial',
    name: 'Minha Ultragaz',
    description:
      'With the challenge of reducing the volume of telephone calls, we worked on developing a new business customer relationship channel solution on the front-end. We utilized a combination of Design Sprint, Scrum, and backend development by Sensedia to create this solution, with the goal of significantly reducing the volume of calls after its implementation. As part of the Front End development team, I was responsible for implementing the layout developed by the Ui & Ux team and integrating it with all the functionalities provided by the APIs.',
    image: {
      url: '/assets/images/projects/minha-ultragaz.jpg',
    },
    madeAt: 'Zappts',
    madeAtUrl: 'https://zappts.com.br',
    link: 'https://minhaultragaz.com.br',
    featuredProject: false,
    year: 2018,
    stack: ['React'],
  },
  {
    id: uuidv4(),
    slug: 'auttar-backoffice',
    name: 'Auttar Backoffice',
    description:
      "This project involved designing solutions for various channels, such as reconciliation portal, BackOffice, customer portal, and POS interface. We built a responsive web application using the UI KIT for standardizing the designs of the developed products, in addition to integrating with the company's legacy systems and developing new functionalities for internal use systems. As a result, we delivered a unified platform for both end customers and internal users, with a focus on the digital experience and considering different purposes and journeys.",
    image: {
      url: '/assets/images/projects/auttar-back-office.jpeg',
    },
    madeAt: 'Zappts',
    madeAtUrl: 'https://zappts.com.br',
    link: 'https://www.auttar.com.br',
    featuredProject: false,
    year: 2021,
    stack: ['React'],
  },
  {
    id: uuidv4(),
    slug: 'map-ultragaz',
    name: 'MAP Ultragaz',
    description:
      'The project involved the design and development of digital journeys aimed at end consumers and resellers. The project ensured integration with back-office systems, the provision of customized content by user profile, the use of gamification, and included a multi-platform implementation. As a result, we optimized the relationship with the audience by integrating Salesforce and WhatsApp. The solution allowed for the centralization of requests, reducing the opening of tickets and the SLA (Service Level Agreement).',
    image: {
      url: '/assets/images/projects/map.jpeg',
    },
    madeAt: 'Zappts',
    madeAtUrl: 'https://zappts.com.br',
    link: 'https://www.mapultragaz.com.br',
    featuredProject: false,
    year: 2020,
    stack: ['React'],
  },
  {
    id: uuidv4(),
    slug: 'alix-rio',
    name: 'Alix.Rio',
    description:
      'Founded in 2018, Alix.Rio is a Brazilian clothing and accessories brand. Its collections focus on different cultures and visual references, based on the research of its creative director, Alix Duvernoy. The brand believes in artisanal production, carried out on a small scale and with fair remuneration for all parties who collaborate with us throughout the process. On the labels of the embroidered pieces, for example, the number of hours spent by our embroiderers on each piece are shared, always handmade by artisans with whom we have established working and affectionate relationships since the first collections. We believe in conscious and committed fashion, both with the world around us and with those who collaborate with our work and dreams.',
    image: {
      url: '/assets/images/projects/alixrio.png',
    },
    madeAt: 'Camila Bossolan',
    madeAtUrl: 'https://camilabossolan.com.br',
    link: 'https://alixrio.com',
    featuredProject: false,
    year: 2023,
    stack: ['Shopify'],
  },
  {
    id: uuidv4(),
    slug: 'inwi',
    name: 'INWI',
    description:
      "Founded in 2015 by Fatima Zorzato, INWI is born with a history of more than 20 years of experience in selecting, guiding, and evaluating executives. Their extensive know-how in the context of family-owned companies is INWI's greatest differential. Through this experience, they reinvent traditional search with the owner's perspective, working in complex and emotional situations for family-owned companies, defined control, and private equity funds.",
    image: {
      url: '/assets/images/projects/inwi.jpg',
    },
    madeAt: 'Camila Bossolan',
    madeAtUrl: 'https://camilabossolan.com.br',
    link: 'https://inwi.com.br',
    featuredProject: false,
    year: 2023,
    stack: ['React', 'Next.js', 'GraphQL'],
  },
  {
    id: uuidv4(),
    slug: '7the',
    name: '7th Experience Events',
    description:
      'The 7TH Experience was born with the purpose of revolutionizing the corporate events experience, taking knowledge and networking to the next level. With over 15 years of accumulated experience in strategic networking, we connect people and ideas to generate business and value in Brazil and around the world. Going far beyond content, our vision is that each event is a unique experience, adding value not only to the professional moment or career but also providing engagement and support in decision-making, inspiring ideas and innovative solutions in the face of uncertain scenarios.',
    image: {
      url: '/assets/images/projects/7the.jpg',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: 'https://7the.com.br',
    featuredProject: false,
    year: 2023,
    stack: ['React', 'Next.js', 'GraphQL'],
  },
  {
    id: uuidv4(),
    slug: 'camilabossolan',
    name: 'Camila Bossolan',
    description:
      "Camila Bossolan is an award-winning creative director and digital strategy consultant with more than fifteen years of experience working with high profile brands on an international scale. Based between London and São Paulo, she launched her own boutique creative studio in May 2014, taking on an array of clients. Previously digital art director and designer at the creative agency Winkreative. She has designed for clients across a range of sectors, among them: the Whitechapel Art Gallery, Curzon Cinemas, The Belvedere Hotel, BMW Group, L'Officiel Italia, Vogue Magazine and MINI.",
    image: {
      url: '/assets/images/projects/camilabossolan.jpg',
    },
    madeAt: 'Camila Bossolan',
    madeAtUrl: 'https://camilabossolan.com.br',
    link: 'https://camilabossolan.com.br',
    featuredProject: false,
    year: 2023,
    stack: ['React', 'Next.js'],
  },
  {
    id: uuidv4(),
    slug: 'farfarm',
    name: 'Farfarm',
    description:
      'FARFARM is a consultancy company specializing in supply-chain networks, from organic cotton plantation using Regenerative Agroforestry to researching new materials. Their mission is to use Fashion as a tool to regenerate nature and promote social development.',
    image: {
      url: '/assets/images/projects/farfarm.jpg',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: 'https://farfarm.co',
    featuredProject: false,
    year: 2022,
    stack: ['React', 'Next.js', 'GraphQL'],
  },
  {
    id: uuidv4(),
    slug: 'proeza-design-studio',
    name: 'Proeza Design Studio',
    description:
      'Proeza is a design studio that specializes in building brands and developing businesses. Their focus is on collaborating with new businesses or projects, including startups that need to structure themselves better as a brand.',
    image: {
      url: '/assets/images/projects/proeza.jpg',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: 'https://proezastudio.com',
    featuredProject: false,
    year: 2023,
    stack: ['React', 'Next.js'],
  },
  {
    id: uuidv4(),
    slug: 'marche',
    name: 'St Marche HR Manager',
    description:
      'St. Marche HR Manager is an extension of the RH+ Portal, which is a web application developed to manage the headcount and ensure the application of Human Resources policies in the movement of employees at St Marche.',
    image: {
      url: '/assets/images/projects/marche.jpeg',
    },
    madeAt: 'Romasoft',
    madeAtUrl: 'https://romasofttecnologia.com.br',
    link: 'https://www.marche.com.br',
    featuredProject: false,
    year: 2019,
    stack: ['React'],
  },
  {
    id: uuidv4(),
    slug: 'folheto-na-mao',
    name: 'Folheto na mão',
    description:
      'Folheto na mão is an online service that provides access to all available promotional flyers and the best offers, subdivided by region. With a simple registration, users can enter their location to access leaflets in their vicinity or the region they choose. The site offers the possibility for users to receive real-time notifications about the best offers in their proximity or chosen supermarkets. We provide all the necessary information about the desired supermarket, including a link to the online store and a Google Maps link for directions to the physical store. On our website, users can compare prices, plan and optimize their purchases.',
    image: {
      url: '/assets/images/projects/folhetonamao.jpeg',
    },
    madeAt: 'Folheto na mão',
    madeAtUrl: 'https://folhetonamao.com.br',
    link: 'https://folhetonamao.com.br',
    featuredProject: false,
    year: 2021,
    stack: ['React', 'NodeJS'],
  },
  {
    id: uuidv4(),
    slug: 'interagir',
    name: 'Interagir',
    description:
      'Interagir is a customer engagement platform that enables businesses to create, manage, and send customized surveys to their customers. The platform allows businesses to tailor their satisfaction survey campaigns to their specific needs using a simple and user-friendly interface. With Interagir, businesses can collect valuable feedback from their customers, measure satisfaction levels, and identify areas for improvement in their products or services. The platform also provides real-time analytics and reporting, allowing businesses to track their survey results and take immediate action to improve their customer satisfaction ratings.',
    image: {
      url: '/assets/images/projects/interagir.jpeg',
    },
    madeAt: 'Interagir',
    madeAtUrl: 'https://interagir.co',
    link: 'https://interagir.co',
    featuredProject: false,
    year: 2021,
    stack: ['React'],
  },
  {
    id: uuidv4(),
    slug: 'cunho-portugues',
    name: 'Cunho Português',
    description:
      'Cunho Português was born with the ambition to promote the best that Portugal has in the agri-food and well-being sector. We are lovers of our country and the history that follows it. We value national production and work to preserve Portuguese culture. Wherever you are, we want you to have a true Portuguese touch with you to accompany you in the most special and unique moments of your life, experiencing the best tasting that the Portuguese regions have to offer.',
    image: {
      url: '/assets/images/projects/cunhoportugues.jpeg',
    },
    madeAt: 'Macro Makers',
    madeAtUrl: 'https://macromakers.pt',
    link: 'https://cunhoportugues.pt',
    featuredProject: false,
    year: 2022,
    stack: ['React', 'Next.js'],
  },
  {
    id: uuidv4(),
    slug: 'vifesolutions',
    name: 'VIFE Solutions',
    description:
      'VIFE Solutions is a multidisciplinary company of digital product specialists focused on branding, UI/UX design, mobile and web development. We extend the design, development and marketing departments of growing startups and established companies.',
    image: {
      url: '/assets/images/projects/vifesolutions.png',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: 'https://vifesolutions.com',
    featuredProject: false,
    year: 2022,
    stack: ['React', 'Next.js'],
  },
  {
    id: uuidv4(),
    slug: 'rende-mais',
    name: 'Clube Rende Mais',
    description:
      'A loyalty app created by Romasoft Tecnologia for Rede Passos customers, where you can easily and conveniently manage your discounts, accumulate and redeem them on Rede Passos, and enjoy exclusive offers and experiences.',
    image: {
      url: '/assets/images/projects/rende-mais.jpeg',
    },
    madeAt: 'Romasoft',
    madeAtUrl: 'https://romasofttecnologia.com.br',
    link: 'https://clubepassos.com.br',
    featuredProject: false,
    year: 2018,
    stack: ['NodeJS', 'React Native'],
  },
  {
    id: uuidv4(),
    slug: 'netlit',
    name: 'Netlit',
    description:
      "NetLit is the subscription service for digital books from Editora do Brasil's children's catalog. The platform offers hundreds of books, from Kindergarten to High School, in e-Pub format, to be read online by cell phone, tablet, or computer. Learn about NetLit's plans and encourage your family and students to get into the habit of reading.",
    image: {
      url: '/assets/images/projects/netlit.jpeg',
    },
    madeAt: 'Editora do Brasil',
    madeAtUrl: 'https://editoradobrasil.com.br',
    link: 'https://netlit.com.br',
    featuredProject: false,
    year: 2019,
    stack: ['React', 'Next.js'],
  },
  {
    id: uuidv4(),
    slug: 'teocenter',
    name: 'Wittlich Teocenter',
    description:
      "Wittlich Teocenter has been in the Bearings, Belts, Retainers, Pulleys, Couplings market for over 70 years, among other industrial, agricultural, and automotive parts. And to further expand your market, we created the company's e-commerce, thus being able to reach every city in the country.",
    image: {
      url: '/assets/images/projects/teocenter.jpeg',
    },
    madeAt: 'VIFE Solutions',
    madeAtUrl: 'https://vifesolutions.com',
    link: 'https://teocenter.com.br',
    featuredProject: false,
    year: 2018,
    stack: ['HTML', 'CSS', 'JavaScript', 'PHP'],
  },
  {
    id: uuidv4(),
    slug: 'agenda4pets',
    name: 'Agenda4Pets',
    description:
      'The Agenda4Pets application is coming to the market to bring more ease and agility when scheduling appointments at the pet shop for your furry friend, whether they are a dog, cat, or mini pig. We deliver more and more experience, technology, and business competitiveness to the market. Agenda4Pets believes in innovation and entrepreneurship as fundamental pillars of this journey.',
    image: {
      url: '/assets/images/projects/agenda4pets.jpeg',
    },
    madeAt: 'Agenda4Pets',
    madeAtUrl: 'https://www.agenda4pets.com.br',
    link: 'https://www.agenda4pets.com.br',
    featuredProject: false,
    year: 2019,
    stack: ['React'],
  },
  {
    id: uuidv4(),
    slug: 'provi',
    name: 'Provi',
    description:
      'Provi is a fintech whose purpose is to create bridges that promote educational access in Brazil. We are here to simplify the process of selling and purchasing courses, and for that, we offer financial solutions that empower educational institutions and info-producers, helping them to reach even more students and simplify financial management.',
    image: {
      url: '/assets/images/projects/provi.jpeg',
    },
    madeAt: 'Provi',
    madeAtUrl: 'https://provi.com.br',
    link: 'https://provi.com.br',
    featuredProject: false,
    year: 2018,
    stack: ['React Native'],
  },
  {
    id: uuidv4(),
    slug: 'romasoft-hr',
    name: 'Romasoft HR',
    description:
      'Modernize your HR routine with our software! Manage your headcount and employee movements with a user-friendly platform that helps you organize compensation and streamline HR processes. The RH+ Portal is a web application specifically designed to manage headcount and ensure the proper application of HR policies in employee movements.',
    image: {
      url: '/assets/images/projects/romasoft-hr.jpeg',
    },
    madeAt: 'Romasoft',
    madeAtUrl: 'https://romasofttecnologia.com.br',
    link: 'https://romasofttecnologia.com.br',
    featuredProject: false,
    year: 2018,
    stack: ['React', 'C#'],
  },
  {
    id: uuidv4(),
    slug: 'iclubs',
    name: 'iClubs App',
    description:
      'This project involved the development of an app and website for making donations to institutions, redeeming coupons, and taking advantage of in-store offers. The developed system ensured flexibility to create and manage loyalty programs and personalized incentives at scale. As a result, we achieved system integration with various data sources, the ability to segment dynamic audiences, identification of purchasing behaviors, and optimization of recognition and rewards actions through the platform.',
    image: {
      url: '/assets/images/projects/iclubs.jpeg',
    },
    madeAt: 'Zappts',
    madeAtUrl: 'https://zappts.com.br',
    link: 'https://iclubs.com.br',
    featuredProject: false,
    year: 2019,
    stack: ['React Native'],
  },
  {
    id: uuidv4(),
    slug: 'advsite',
    name: 'Advsite',
    description:
      "Having a website for your law firm is critical given the growth of online consumption. By making your data available on the web, your visibility and credibility increase. That's why we created AdvSite, a product that allows lawyers to have a personalized website with their most important information through a modern and responsive design.",
    image: {
      url: '/assets/images/projects/advsite.jpeg',
    },
    madeAt: 'Romasoft',
    madeAtUrl: 'https://romasofttecnologia.com.br',
    link: 'https://www.advsite.com.br',
    featuredProject: false,
    year: 2018,
    stack: ['HTML', 'CSS', 'JavaScript'],
  },
  {
    id: uuidv4(),
    slug: 'romasoft',
    name: 'Romasoft Tecnologia',
    description:
      'With over 17 years of experience in software development and a team of full-time professionals, we are dedicated to delivering high-performance solutions with a focus on user experience and customer satisfaction.',
    image: {
      url: '/assets/images/projects/romasoft.png',
    },
    madeAt: 'Romasoft',
    madeAtUrl: 'https://romasofttecnologia.com.br',
    link: 'https://romasofttecnologia.com.br',
    featuredProject: false,
    year: 2018,
    stack: ['Wordpress', 'PHP'],
  },
  {
    id: uuidv4(),
    slug: 'keromais',
    name: 'Panificadora Keromais',
    description:
      'Keromais Bakery, one of the most renowned and established bread shops in Guaratinguetá, entrusted me with the task of creating a landing page to showcase its services and offerings.',
    image: {
      url: '/assets/images/projects/keromais.png',
    },
    madeAt: 'Keromais',
    madeAtUrl: 'https://panificadorakeromais.com.br',
    link: 'https://panificadorakeromais.com.br',
    featuredProject: false,
    year: 2017,
    stack: ['HTML', 'CSS', 'JavaScript'],
  },
  {
    id: uuidv4(),
    slug: 'inpe',
    name: 'INPE',
    description:
      'Investigate the characteristics of precipitation, air temperature, relative humidity and wind, carrying out a survey of the historical data of the cities of Vale do Paraíba and Litoral Norte de São Paulo. To elaborate a climatological research and to calculate the anomaly of some meteorological variables, and through a web tool, to provide a visualization of the information by the DIDOP Group of Forecast of Time of the National Institute of Space Research (INPE).',
    image: {
      url: '/assets/images/projects/inpe.png',
    },
    madeAt: 'INPE',
    madeAtUrl: 'https://inpe.gov.br',
    link: 'https://inpe.gov.br',
    featuredProject: false,
    year: 2018,
    stack: ['HTML', 'CSS', 'JavaScript', 'PHP'],
  },
  {
    id: uuidv4(),
    slug: 'unisal',
    name: 'Atlética UNISAL',
    description:
      'Atlética Unisal is a website created to promote the events, photos, announcements, schedules, and more for the Associação Atlética of the Universidade Salesiana de São Paulo. The platform serves as a central hub for students, athletes, and the university community to stay updated on all activities related to the athletic association. With a focus on user engagement and ease of navigation, the site offers a streamlined experience for accessing event information, viewing photo galleries, staying informed with important announcements, and tracking sports schedules. The goal of the platform is to foster community spirit, highlight athletic achievements, and keep everyone connected with the latest updates from Atlética Unisal.',
    image: {
      url: '/assets/images/projects/atleticaunisal.png',
    },
    madeAt: 'INPE',
    madeAtUrl: 'https://unisal.br',
    link: 'https://atleticaunisal.com.br',
    featuredProject: false,
    year: 2017,
    stack: ['HTML', 'CSS', 'JavaScript', 'PHP'],
  },
  {
    id: uuidv4(),
    slug: 'unisal',
    name: 'Toledo Costa',
    description:
      "Wesley Toledo Costa is a renowned professor in the academic world, specializing in the field of technology. His website was created as a digital showcase of his academic achievements and projects. Designed to highlight his contributions to research, teaching, and innovation, the site provides an in-depth look at his work, including published papers, speaking engagements, and the projects he has led in the technology sector. Serving as both a professional portfolio and a resource for students and colleagues, the platform reflects Wesley's dedication to advancing knowledge in his field and inspiring the next generation of tech professionals.",
    image: {
      url: '/assets/images/projects/toledocosta.png',
    },
    madeAt: 'INPE',
    madeAtUrl: 'https://unisal.br',
    link: 'https://toledocosta.com',
    featuredProject: false,
    year: 2017,
    stack: ['HTML', 'CSS', 'JavaScript', 'PHP'],
  },
  {
    id: uuidv4(),
    slug: 'gladiadorcraft',
    name: 'GladiadorCraff PvP',
    description:
      'GladiadorCraft PvP was one of the first full PvP Minecraft servers in Brazil, founded in 2012 when I was just 12 years old, along with two friends. We developed the entire server ourselves, marking the beginning of my journey in web development with our first site built on Wix, which was quite different from today’s standards. At our peak, we became the second-largest server in Brazil, attracting thousands of players daily.',
    image: {
      url: '/assets/images/projects/gladiadorcraft.png',
    },
    madeAt: 'VG Studio',
    madeAtUrl: 'https://vinniciusgomes.dev',
    link: 'https://pvpgladiadorcraft.wixsite.com/home',
    featuredProject: false,
    year: 2012,
    stack: ['Wix'],
  },
]
