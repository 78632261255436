import {createGlobalStyle, css} from 'styled-components'

const GlobalStyleProxy: any = createGlobalStyle`
  ${({theme}) => css`
    @font-face {
      font-family: 'Fira Code';
      src: url('/assets/fonts/FiraCode-Regular.woff2') format('woff2'),
      url('/assets/fonts/FiraCode-Regular.woff') format('woff');
      font-weight: 'normal';
      font-style: 'normal';
    }

    ::-webkit-scrollbar-track:horizontal {
      background-color: ${theme.colors.background};
    }

    ::-webkit-scrollbar {
      width: 0.4rem;
      background: ${theme.colors.background};
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.background};
    }

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-display: swap;
      outline: none;
    }

    .radix-themes {
      --my-brand-color: ${theme.colors.highlightColor};
      --color-background: ${theme.colors.background};
      --font-weight-light: ${theme.font.weights.light};
      --font-weight-regular: ${theme.font.weights.normal};
      --font-weight-medium: ${theme.font.weights.medium};
      --font-weight-bold: ${theme.font.weights.bold};
      --default-font-size: 1.6rem;
    }

    html {
      font-size: 62.5%;
    }

    html,
    body {
      margin: 0;
      padding: 0;
    }

    body {
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      border-right: 0 !important;
      overflow-x: hidden;
      background: ${theme.colors.background} !important;
      font-size: 1.6rem;
      -webkit-font-smoothing: antialiased;
    }

    body,
    input,
    textarea,
    button {
      font-weight: ${theme.font.weights.normal};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: ${theme.font.weights.bold};
      color: ${theme.colors.title};
    }

    strong {
      font-weight: ${theme.font.weights.semiBold};
      color: ${theme.colors.title};
    }

    span {
      font-size: ${theme.font.sizes.normal};
      letter-spacing: -0.02rem;
    }

    button {
      cursor: pointer !important;
      font-family: var(--font-geist-sans);
      font-weight: 500;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    [disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }

    [data-rmiz-modal-overlay='visible'] {
      background-color: transparent;
      backdrop-filter: blur(8px);
      background-color: ${theme.colors.headerColor};
      border-bottom: 0.1rem solid ${theme.colors.headerBottomColor};
    }
  `}
`

export default GlobalStyleProxy
